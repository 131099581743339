import React from 'react';
import Switch from '@material-ui/core/Switch';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { setStatusSwitch } from '../../../../../actions/home';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: '#7745FE',
            '& + .MuiSwitch-track': {
                background: 'linear-gradient(90deg, #D61D6A 0%, #7645FF 100%)',
            },
        },
    },
    switchBase: {
        color: '#696969',
    },
    track: {
        background: '#555555',
    },
}));

const StatusSwitchField = (props) => {
    const handleChange = (event) => {
        props.onChange(event.target.checked);
    };

    return (
        <div className="status_switch">
            <p>Status</p>
            <Switch
                checked={props.value}
                className="switch"
                classes={{
                    root: useStyles().root,
                    switchBase: useStyles().switchBase,
                    track: useStyles().track,
                }}
                name="status"
                onChange={handleChange}/>
        </div>
    );
};

StatusSwitchField.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        value: state.home.bannerStatusSwitch.value,
    };
};

const actionsToProps = {
    onChange: setStatusSwitch,
};

export default connect(stateToProps, actionsToProps)(StatusSwitchField);
