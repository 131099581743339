import { combineReducers } from 'redux';
import {
    COLLECTION_FETCH_ERROR,
    COLLECTION_FETCH_IN_PROGRESS,
    COLLECTION_FETCH_SUCCESS,
    COLLECTION_UPDATE_ERROR,
    COLLECTION_UPDATE_IN_PROGRESS,
    COLLECTION_UPDATE_SUCCESS,
    COLLECTIONS_FETCH_ERROR,
    COLLECTIONS_FETCH_IN_PROGRESS,
    COLLECTIONS_FETCH_SUCCESS,
    DENOM_ID_SET,
    FEATURED_POSITION_DIALOG_HIDE,
    FEATURED_POSITION_DIALOG_SHOW,
    FEATURED_POSITION_SET,
    ITC_CAMPAIGN_UPDATE_ERROR,
    ITC_CAMPAIGN_UPDATE_IN_PROGRESS,
    ITC_CAMPAIGN_UPDATE_SUCCESS,
    ITC_CAMPAIGNS_FETCH_ERROR,
    ITC_CAMPAIGNS_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_FETCH_SUCCESS,
    LAUNCHPAD_DIALOG_HIDE,
    LAUNCHPAD_DIALOG_SHOW,
    LAUNCHPAD_URL_SET,
    MINT_ACCESS_ADDRESS_SET,
    MINT_ACCESS_DIALOG_HIDE,
    MINT_ACCESS_DIALOG_SHOW,
    SHOW_FEATURED_SET,
    BANNER_FETCH_IN_PROGRESS,
    BANNER_FETCH_SUCCESS,
    BANNER_FETCH_ERROR,
    BANNER_LIST_ADD_IN_PROGRESS,
    BANNER_UPDATE_IN_PROGRESS,
    BANNER_DELETE_IN_PROGRESS,
    BANNER_LIST_ADD_SUCCESS,
    BANNER_UPDATE_SUCCESS,
    BANNER_DELETE_SUCCESS,
    BANNER_LIST_ADD_ERROR,
    BANNER_UPDATE_ERROR,
    BANNER_DELETE_ERROR,
    BANNER_LIST_DIALOG_SHOW,
    BANNER_LIST_DIALOG_HIDE,
    BANNER_NAME_SET,
    BANNER_START_DATE_SET,
    BANNER_END_DATE_SET,
    DELETE_BANNER_DIALOG_SHOW,
    DELETE_BANNER_DIALOG_HIDE,
    BANNER_TYPE_SET,
    BANNER_QUICK_VIEW_SHOW,
    BANNER_QUICK_VIEW_HIDE,
    MOBILE_IMAGE_UPLOAD_IN_PROGRESS,
    MOBILE_IMAGE_UPLOAD_SUCCESS,
    MOBILE_IMAGE_UPLOAD_ERROR,
    MOBILE_IMAGE_URL_SET,
    WEB_IMAGE_UPLOAD_IN_PROGRESS,
    WEB_IMAGE_UPLOAD_SUCCESS,
    WEB_IMAGE_UPLOAD_ERROR,
    TAB_IMAGE_UPLOAD_IN_PROGRESS,
    TAB_IMAGE_UPLOAD_SUCCESS,
    TAB_IMAGE_UPLOAD_ERROR,
    LARGE_SCREEN_IMAGE_UPLOAD_IN_PROGRESS,
    LARGE_SCREEN_UPLOAD_SUCCESS,
    LARGE_SCREEN_IMAGE_UPLOAD_ERROR,
    WEB_IMAGE_URL_SET,
    TAB_IMAGE_URL_SET,
    LARGE_SCREEN_IMAGE_URL_SET,
    STATUS_SWITCH_SET,
    BANNER_REDIRECTION_URL_SET,
} from '../constants/home';
import { DEFAULT_COUNT, DEFAULT_ORDER, DEFAULT_SORT_BY } from '../config';

const collections = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTIONS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case COLLECTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const mintAccessAddress = (state = '', action) => {
    if (action.type === MINT_ACCESS_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const mintAccessDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_ACCESS_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };

    case MINT_ACCESS_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const collectionUpdate = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case COLLECTIONS_FETCH_IN_PROGRESS:
    case COLLECTION_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTIONS_FETCH_SUCCESS:
    case COLLECTION_UPDATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case COLLECTIONS_FETCH_ERROR:
    case COLLECTION_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const launchpad = (state = {
    open: false,
    url: '',
    denomId: '',
    data: {},
}, action) => {
    switch (action.type) {
    case LAUNCHPAD_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            data: action.value,
        };
    case LAUNCHPAD_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            url: '',
            denomId: '',
            data: {},
        };
    case LAUNCHPAD_URL_SET:
        return {
            ...state,
            url: action.value,
        };
    case DENOM_ID_SET:
        return {
            ...state,
            denomId: action.value,
        };
    default:
        return state;
    }
};

const collection = (state = {
    data: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case COLLECTION_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COLLECTION_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
        };
    case COLLECTION_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case LAUNCHPAD_DIALOG_HIDE:
        return {
            ...state,
            data: {},
        };
    default:
        return state;
    }
};

const featuredPosition = (state = '', action) => {
    if (action.type === FEATURED_POSITION_SET) {
        return action.value;
    } else if (action.type === FEATURED_POSITION_DIALOG_SHOW) {
        return action.value && action.value.featured && action.value.featured_position ? (action.value.featured_position) : '';
    } else if (action.type === FEATURED_POSITION_DIALOG_HIDE) {
        return '';
    }

    return state;
};

const showFeatured = (state = false, action) => {
    if (action.type === SHOW_FEATURED_SET) {
        return action.value;
    }

    return state;
};

const featuredPositionDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FEATURED_POSITION_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };

    case FEATURED_POSITION_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const itcCampaigns = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case ITC_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcCampaignUpdate = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_FETCH_IN_PROGRESS:
    case ITC_CAMPAIGN_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_FETCH_SUCCESS:
    case ITC_CAMPAIGN_UPDATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case ITC_CAMPAIGNS_FETCH_ERROR:
    case ITC_CAMPAIGN_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const bannersList = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case BANNER_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case BANNER_FETCH_SUCCESS:
        return {
            ...state,
            list: action.value,
            inProgress: false,
        };
    case BANNER_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addBanner = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case BANNER_LIST_ADD_IN_PROGRESS:
    case BANNER_UPDATE_IN_PROGRESS:
    case BANNER_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case BANNER_LIST_ADD_SUCCESS:
    case BANNER_UPDATE_SUCCESS:
    case BANNER_DELETE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case BANNER_LIST_ADD_ERROR:
    case BANNER_UPDATE_ERROR:
    case BANNER_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const bannerListDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case BANNER_LIST_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case BANNER_LIST_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const deleteBannerDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DELETE_BANNER_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_BANNER_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const bannerQuickView = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case BANNER_QUICK_VIEW_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case BANNER_QUICK_VIEW_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const nameValue = (state = '', action) => {
    if (action.type === BANNER_NAME_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.name) || '';
    }

    return state;
};

const startDate = (state = null, action) => {
    if (action.type === BANNER_START_DATE_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.start_time) || null;
    }

    return state;
};

const endDate = (state = null, action) => {
    if (action.type === BANNER_END_DATE_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.end_time) || null;
    }

    return state;
};

const bannerType = (state = '', action) => {
    if (action.type === BANNER_TYPE_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.name) || '';
    }

    return state;
};

const bannerMobileImageValue = (state = '', action) => {
    if (action.type === MOBILE_IMAGE_URL_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.image && action.value.image.mobile) || '';
    }
    return state;
};

const bannerWebImageValue = (state = '', action) => {
    if (action.type === WEB_IMAGE_URL_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.image && action.value.image.web) || '';
    }
    return state;
};

const bannerTabImageValue = (state = '', action) => {
    if (action.type === TAB_IMAGE_URL_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.image && action.value.image.tab) || '';
    }
    return state;
};

const bannerLargeScreenImageValue = (state = '', action) => {
    if (action.type === LARGE_SCREEN_IMAGE_URL_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.image && action.value.image.large_screen) || '';
    }
    return state;
};

const bannerRedirectionUrlValue = (state = '', action) => {
    if (action.type === BANNER_REDIRECTION_URL_SET) {
        return action.value;
    } else if (action.type === BANNER_LIST_DIALOG_SHOW) {
        return (action.value && action.value.redirection_url) || '';
    }
    return state;
};

const mobileImageUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MOBILE_IMAGE_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const webImageUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case WEB_IMAGE_URL_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const tabImageUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case TAB_IMAGE_URL_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const largeScreenImageUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case LARGE_SCREEN_IMAGE_URL_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const mobileImage = (state = {
    inProgress: false,
    value: null,
}, action) => {
    switch (action.type) {
    case MOBILE_IMAGE_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MOBILE_IMAGE_UPLOAD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MOBILE_IMAGE_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const webImage = (state = {
    inProgress: false,
    value: null,
}, action) => {
    switch (action.type) {
    case WEB_IMAGE_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case WEB_IMAGE_UPLOAD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case WEB_IMAGE_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const tabImage = (state = {
    inProgress: false,
    value: null,
}, action) => {
    switch (action.type) {
    case TAB_IMAGE_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TAB_IMAGE_UPLOAD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TAB_IMAGE_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const largeScreenImage = (state = {
    inProgress: false,
    value: null,
}, action) => {
    switch (action.type) {
    case LARGE_SCREEN_IMAGE_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LARGE_SCREEN_UPLOAD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case LARGE_SCREEN_IMAGE_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const bannerStatusSwitch = (state = {
    value: true,
}, action) => {
    switch (action.type) {
    case STATUS_SWITCH_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

export default combineReducers({
    collections,
    mintAccessAddress,
    mintAccessDialog,
    collectionUpdate,
    launchpad,
    collection,
    featuredPosition,
    showFeatured,
    featuredPositionDialog,

    itcCampaigns,
    itcCampaignUpdate,

    bannersList,
    addBanner,
    bannerListDialog,
    deleteBannerDialog,
    bannerQuickView,
    nameValue,
    startDate,
    endDate,
    bannerType,
    bannerMobileImageValue,
    bannerWebImageValue,
    bannerTabImageValue,
    bannerLargeScreenImageValue,
    bannerRedirectionUrlValue,

    mobileImageUrl,
    webImageUrl,
    tabImageUrl,
    largeScreenImageUrl,
    mobileImage,
    webImage,
    tabImage,
    largeScreenImage,
    bannerStatusSwitch,
});
