import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../../../components/TextField';
import variables from '../../../../../utils/variables';
import { setBannerRedirectionUrl } from '../../../../../actions/home';

const RedirectionUrlField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="redirection_url_field"
            id="url"
            name="redirection_url"
            placeholder={variables[props.lang]['enter_redirection_url']}
            value={props.value}
            onChange={handleChange}/>
    );
};

RedirectionUrlField.propTypes = {
    lang: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.home.bannerRedirectionUrlValue,
    };
};

const actionToProps = {
    onChange: setBannerRedirectionUrl,
};

export default connect(stateToProps, actionToProps)(RedirectionUrlField);
