import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import { USER_LOGIN_ERROR, USER_LOGIN_SUCCESS } from '../constants/login';
import { COLLECTION_UPDATE_ERROR, COLLECTION_UPDATE_SUCCESS, COLLECTIONS_FETCH_ERROR } from '../constants/home';
import { CAMPAIGN_LIST_ADD_ERROR, CAMPAIGN_LIST_ADD_SUCCESS, CAMPAIGNS_FETCH_ERROR } from '../constants/campaign';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case CAMPAIGNS_FETCH_ERROR:
    case CAMPAIGN_LIST_ADD_SUCCESS:
    case CAMPAIGN_LIST_ADD_ERROR:
    case MESSAGE_SHOW:
    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_ERROR:
    case COLLECTIONS_FETCH_ERROR:
    case COLLECTION_UPDATE_SUCCESS:
    case COLLECTION_UPDATE_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
