import React from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCampaignList, fetchCampaigns, hideDeleteCampaignDialog } from '../../../../../actions/campaign';
import { showMessage } from '../../../../../actions/snackbar';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../config';
import CircularProgress from '../../../../../components/CircularProgress';

const DeleteCampaignDialog = (props) => {
    const deleteCampaign = () => {
        if (props.value && props.value._id) {
            props.deleteCampaignList(props.value._id, (cb) => {
                if (!cb) {
                    props.hideDeleteCampaignDialog();
                    props.fetchCampaigns(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
                }
            });
        } else {
            props.showMessage('Something went wrong!');
        }
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog campaign_dialog"
            open={props.open}
            onClose={props.hideDeleteCampaignDialog}>
            <div className="mint_access campaign_dialog_section">
                <h2> Are you sure to delete
                    <bold>&nbsp;{props.value && props.value.name}&nbsp;</bold>
                    Campaign?
                </h2>
                <Button style={{ marginTop: '50px' }} onClick={deleteCampaign}>
                    {props.inProgress && <CircularProgress/>}
                    Delete Campaign
                </Button>
            </div>
        </Dialog>
    );
};

DeleteCampaignDialog.propTypes = {
    deleteCampaignList: PropTypes.func.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
    hideDeleteCampaignDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    showMessage: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.campaign.deleteCampaignDialog.open,
        value: state.campaign.deleteCampaignDialog.value,

        inProgress: state.campaign.addCampaign.inProgress,
    };
};

const actionsToProps = {
    hideDeleteCampaignDialog,
    showMessage,
    deleteCampaignList,
    fetchCampaigns,
};

export default connect(stateToProps, actionsToProps)(DeleteCampaignDialog);
