import React from 'react';
import Drawer from '@mui/material/Drawer';
import * as PropTypes from 'prop-types';
import { hideBannerQuickView } from '../../../../actions/home';
import { connect } from 'react-redux';
import OutsideClick from 'detect-outside-click-react';
import moment from 'moment/moment';
import ImageOnLoad from '../../../../components/ImageOnLoad/BackgroundImage';
import './index.css';

const QuickView = (props) => {
    const handleRedirect = (value) => {
        window.open(value);
    };
    return (
        <Drawer
            anchor="right"
            className="banner_drawer"
            open={props.open}
            variant="persistent"
            onClose={() => props.hideBannerQuickView()}
            onEscapeKeyDown={() => props.hideBannerQuickView()}>
            <OutsideClick close={() => props.hideBannerQuickView()}>
                <div className="drawer_list">
                    <div>
                        <span>Type</span>
                        <p>{props.value && props.value.type}</p>
                    </div>
                    <div>
                        <span>Name</span>
                        <p>{props.value && props.value.name}</p>
                    </div>
                    {props.value && props.value.start_time &&
                        <div>
                            <span>Start Date</span>
                            <p>{props.value && moment(props.value.start_time).format('MMM Do \'YY')}</p>
                        </div>}
                    {props.value && props.value.end_time &&
                        <div>
                            <span>End Date</span>
                            <p>{props.value && moment(props.value.end_time).format('MMM Do \'YY')}</p>
                        </div>}
                    <div className="image_section">
                        <div>
                            <span>Mobile Image</span>
                            <ImageOnLoad
                                className="image"
                                src={props.value && props.value.image && props.value.image.mobile}/>
                        </div>
                        <div>
                            <span>Web Image</span>
                            <ImageOnLoad
                                className="image"
                                src={props.value && props.value.image && props.value.image.web}/>
                        </div>
                        <div>
                            <span>Tab Image</span>
                            <ImageOnLoad
                                className="image"
                                src={props.value && props.value.image && props.value.image.tab}/>
                        </div>
                        <div>
                            <span>Large Screen Image</span>
                            <ImageOnLoad
                                className="image"
                                src={props.value && props.value.image && props.value.image.large_screen}/>
                        </div>
                    </div>
                    <div className="redirection_url" onClick={() => handleRedirect(props.value.redirection_url)}>
                        <span>Redirection URL</span>
                        <p>{props.value && props.value.redirection_url}</p>
                    </div>
                </div>
            </OutsideClick>
        </Drawer>
    );
};

QuickView.propTypes = {
    hideBannerQuickView: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.bannerQuickView.open,
        value: state.home.bannerQuickView.value,
    };
};

const actionToProps = {
    hideBannerQuickView,
};

export default connect(stateToProps, actionToProps)(QuickView);
