import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import DataTable from '../../../../components/DataTable';
import {
    fetchBanners,
    showBannerListDialog,
    showDeleteBannerDialog,
    showBannerQuickView,
} from '../../../../actions/home';
import { connect } from 'react-redux';
import CircularProgress from '../../../../components/CircularProgress';
import Icon from '../../../../components/Icon';
import moment from 'moment';
import thumbnail from '../../../../assets/thumbnail.svg';
import ImageOnLoad from '../../../../components/ImageOnLoad/BackgroundImage';
import { Button } from '@material-ui/core';
import withRouter from '../../../../components/WithRouter';

const Table = (props) => {
    const handleRedirect = (value) => {
        window.open(value);
    };

    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchBanners(searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchBanners();
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchBanners(numberOfRows);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchBanners(changedColumn, direction);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
        customToolbar: () => {
            return (
                <div
                    className="add_button"
                    onClick={() => props.showBannerListDialog()}>
                    <Icon className="add-circle" icon="add-circle" />
                </div>
            );
        },
    };

    const columns = [{
        type: 'type',
        label: 'Type',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="banner_type">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="banner_name">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'start_date',
        label: 'Start Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value ? moment(value).format('MMM Do \'YY') : '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'end date',
        label: 'End date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value ? moment(value).format('MMM Do \'YY') : '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'mobile image',
        label: 'Mobile Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const image = value || thumbnail;

                return (
                    <ImageOnLoad
                        className="image"
                        src={image}/>
                );
            },
        },
    }, {
        name: 'web image',
        label: 'Web Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const image = value || thumbnail;

                return (
                    <ImageOnLoad
                        className="image"
                        src={image}/>
                );
            },
        },
    }, {
        name: 'tab image',
        label: 'Tab Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const image = value || thumbnail;

                return (
                    <ImageOnLoad
                        className="image"
                        src={image}/>
                );
            },
        },
    }, {
        name: 'large screen image',
        label: 'Large Screen Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const image = value || thumbnail;

                return (
                    <ImageOnLoad
                        className="image"
                        src={image}/>
                );
            },
        },
    }, {
        name: 'redirection URL',
        label: 'Redirection URL',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="redirection_url" onClick={() => handleRedirect(value)}>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        <Button onClick={() => props.showBannerQuickView(value)}>
                            <Icon className="eye" icon="eye" />
                        </Button>
                        <Button onClick={() => props.showBannerListDialog(value)}>
                            <Icon className="edit" icon="edit" />
                        </Button>
                        <Button onClick={() => props.showDeleteBannerDialog(value)}>
                            <Icon className="bin" icon="bin" />
                        </Button>
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.type,
                item.name,
                item.start_time ? item.start_time : '',
                item.end_time ? item.end_time : '',
                item.image && item.image.mobile,
                item.image && item.image.web,
                item.image && item.image.tab,
                item.image && item.image.large_screen,
                item.redirection_url,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

Table.propTypes = {
    fetchBanners: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    showBannerListDialog: PropTypes.func.isRequired,
    showBannerQuickView: PropTypes.func.isRequired,
    showDeleteBannerDialog: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        inProgress: state.home.bannersList.inProgress,
        list: state.home.bannersList.list,
        total: state.home.bannersList.count,
    };
};

const actionToProps = {
    fetchBanners,
    showBannerListDialog,
    showDeleteBannerDialog,
    showBannerQuickView,
};

export default withRouter(connect(stateToProps, actionToProps)(Table));
