import React from 'react';
import SelectMulti from 'react-select';
import * as PropTypes from 'prop-types';
import { fetchLaunchpadDenoms, setCampaignLaunchPadDenoms } from '../../../../../actions/campaign';
import { connect } from 'react-redux';

class DenomsSelectField extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            showGif: false,
            denomsList: [],
        };

        // this.scrollDown = this.scrollDown.bind(this);
        this.handleChangeMulti = this.handleChangeMulti.bind(this);
    }

    handleChangeMulti (e) {
        this.props.setCampaignLaunchPadDenoms(e);
        this.setState({ denomsList: e });
    }

    // scrollDown () {
    //     this.setState({ showGif: true });

    // if ((this.props.list.length < this.props.total) &&
    //     !(this.props.inProgress)) {
    //     this.props.fetchLaunchpadDenoms(this.props.skip + DEFAULT_LIMIT, this.props.limit);
    // }
    // }

    render () {
        const selectStyles = {};
        const options =
            this.props.list ? this.props.list.map((suggestion) => (suggestion.on_launchpad ? {
                value: suggestion._id,
                label: suggestion.name,
            } : {}))
                : [];

        return (
            <div className="denom_select_field launchpad_denom">
                <SelectMulti
                    isMulti
                    className={this.state.denomsList && this.state.denomsList.length > 0
                        ? 'title_list_field' : 'denoms_list title_list_field'}
                    options={options}
                    placeholder="Select Launchpad Denoms"
                    showGif={this.state.showGif}
                    styles={selectStyles}
                    textFieldProps={{
                        label: 'Director(s)',
                        InputLabelProps: {
                            shrink: true,
                        },
                    }}
                    value={this.props.value}
                    onChange={(e) => this.handleChangeMulti(e)}
                    // onMenuScrollToBottom={this.scrollDown}
                />
            </div>
        );
    }
}

DenomsSelectField.propTypes = {
    fetchLaunchpadDenoms: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    setCampaignLaunchPadDenoms: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    value: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.campaign.launchpadDenomsList.list,
        skip: state.campaign.launchpadDenomsList.skip,
        limit: state.campaign.launchpadDenomsList.limit,
        total: state.campaign.launchpadDenomsList.count,
        inProgress: state.campaign.launchpadDenomsList.inProgress,
        value: state.campaign.launchPadDenoms,
    };
};

const actionToProps = {
    fetchLaunchpadDenoms,
    setCampaignLaunchPadDenoms,
};

export default connect(stateToProps, actionToProps)(DenomsSelectField);
