import React from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import moment from 'moment';
import {
    fetchITCCampaigns,
    updateITCCampaign,
} from '../../../actions/home';
import { getAssetType, getAssetTypeExtension } from '../../../utils/strings';
import VideoOnLoad from '../../../components/ImageOnLoad/VideoOnLoad';
import ImageOnLoad from '../../../components/ImageOnLoad/BackgroundImage';
import { Button, Tooltip } from '@material-ui/core';
import thumbnail from '../../../assets/thumbnail.svg';

const ITCCampaignsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchITCCampaigns(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchITCCampaigns(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchITCCampaigns(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            // if (changedColumn === 'isFeatured' && direction === 'desc') {
            //     props.fetchCollections(props.skip, props.limit, changedColumn, direction, props.searchKey, 'isFeatured');
            // } else {
            props.fetchITCCampaigns(props.skip, props.limit, changedColumn, direction, props.searchKey);
            // }
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
    };

    const handleCollection = (collection, section, value) => {
        const id = collection && collection.id;
        let data = {};
        if (section === 'flixdrop') {
            data = {
                flixdrop: value,
            };
        }
        if (section === 'genesisCreator') {
            data = {
                verified: value,
            };
        }

        props.updateITCCampaign(id, data, (cb) => {
            if (!cb) {
                props.fetchITCCampaigns(props.skip, props.limit, props.sortBy, props.order, props.searchKey);
            }
        });
    };

    // const handleLaunchpad = (value) => {
    //     props.showLaunchpadDialog(value);
    //     if (value && value.app_url) {
    //         props.setLaunchpadUrl(value.app_url);
    //     }
    // };

    const columns = [{
        name: 'image',
        label: 'Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const image = value && value.preview_uri ? value.preview_uri : thumbnail;
                const mediaType = value && value.media_type;

                return (
                    <React.Fragment>
                        {mediaType &&
                        getAssetType(mediaType) === 'video'
                            ? <div
                                className="collection_icon inline_video_section">
                                <VideoOnLoad
                                    src={image}
                                    type={mediaType}/>
                            </div>
                            : mediaType &&
                            getAssetType(mediaType) === 'image' && getAssetTypeExtension(mediaType) === 'gif'
                                ? <ImageOnLoad
                                    className="collection_icon"
                                    src={image}/>
                                : <ImageOnLoad
                                    cdn={value && value.cloudflare_cdn && value.cloudflare_cdn.variants}
                                    className="collection_icon"
                                    src={image}/>}
                    </React.Fragment>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Campaign Name',
        options: {
            customBodyRender: function (value) {
                const name = value.name && (value.name || value.symbol);
                return (
                    <Tooltip title={value.name + ' | ' + value.symbol}>
                        <div
                            className="collection_name">
                            {name}
                        </div>
                    </Tooltip>
                );
            },
        },
    }, {
        name: 'id',
        label: 'Campaign ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="to_value tx_hash creator_address">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'max_allowed_claims',
        label: 'Max Claims',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="clm_value">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'remaining_claims',
        label: 'Remaining Claims',
        options: {
            customBodyRender: function (value) {
                return (
                    value
                        ? <div className="clm_value">
                            {value}
                        </div>
                        : <div className="clm_value">
                            --
                        </div>
                );
            },
        },
    }, {
        name: 'claims_count',
        label: 'Claims Count',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    value
                        ? <div className="clm_value">
                            {value}
                        </div>
                        : <div className="clm_value">
                            --
                        </div>
                );
            },
        },
    }, {
        name: 'interaction',
        label: 'Interaction',
        options: {
            customBodyRender: function (value) {
                const interaction = value.interaction === 'INTERACTION_TYPE_BURN'
                    ? 'Burn'
                    : value.interaction === 'INTERACTION_TYPE_TRANSFER'
                        ? 'Transfer'
                        : value.interaction === 'INTERACTION_TYPE_HOLD'
                            ? 'Hold'
                            : null;
                return (
                    <div className="clm_value">
                        {interaction}
                    </div>
                );
            },
        },
    }, {
        name: 'claim_type',
        label: 'Claim Type',
        options: {
            customBodyRender: function (value) {
                const type = value.claim_type === 'CLAIM_TYPE_FT_AND_NFT'
                    ? 'FT & NFT' : value.claim_type === 'CLAIM_TYPE_FT'
                        ? 'FT' : value.claim_type === 'CLAIM_TYPE_NFT'
                            ? 'NFT' : null;
                return (
                    <div className="clm_value">
                        {type}
                    </div>
                );
            },
        },
    }, {
        name: 'creator',
        label: 'Creator',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div
                        className="to_value tx_hash creator_address">
                        <div className="hash_text" title={value}>
                            <p className="name">{value}</p>
                            {value && value.slice(value.length - 6, value.length)}
                        </div>
                    </div>
                );
            },
        },
    }, {
        name: 'verified',
        label: 'Verified',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'created_at',
        label: 'Created Date',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value && moment(value).format('MMM Do \'YY')}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        {value && value.verified
                            ? <Button onClick={() => handleCollection(value, 'genesisCreator', false)}>
                                Un-Verify
                            </Button>
                            : <Button onClick={() => handleCollection(value, 'genesisCreator', true)}>
                                Verify
                            </Button>}
                        {value && value.flixdrop
                            ? <Button onClick={() => handleCollection(value, 'flixdrop', false)}>
                                Un-Tag Flixdrop
                            </Button>
                            : <Button onClick={() => handleCollection(value, 'flixdrop', true)}>
                                Tag Flixdrop
                            </Button>}
                        {/* {value && value.on_launchpad */}
                        {/*    ? <Button */}
                        {/*        className="primary_button" */}
                        {/*        onClick={() => handleLaunchpad(value)}> */}
                        {/*        Update Launchpad */}
                        {/*    </Button> */}
                        {/*    : <Button */}
                        {/*        className="primary_button" */}
                        {/*        onClick={() => handleLaunchpad(value)}> */}
                        {/*        Add to Launchpad */}
                        {/*    </Button>} */}
                        {/* {value && value.featured */}
                        {/*    ? <Button onClick={() => handleCollection(value, 'isFeatured', false)}> */}
                        {/*        Un Feature */}
                        {/*    </Button> */}
                        {/*    : <Button onClick={() => props.showFeaturedPositionDialog(value)}> */}
                        {/*        Feature */}
                        {/*    </Button>} */}
                    </div>

                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item,
                item,
                item.id,
                item.max_allowed_claims,
                item.remaining_claims,
                item.claims_count,
                item,
                item,
                item.creator,
                item.verified ? 'True' : 'False',
                item.created_at ? item.created_at : '-',
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                // name={'Mint Requests'}
                options={options}/>
        </>
    );
};

ITCCampaignsTable.propTypes = {
    fetchITCCampaigns: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    updateITCCampaign: PropTypes.func.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.itcCampaigns.list,
        inProgress: state.home.itcCampaigns.inProgress,
        skip: state.home.itcCampaigns.skip,
        limit: state.home.itcCampaigns.limit,
        sortBy: state.home.itcCampaigns.sortBy,
        order: state.home.itcCampaigns.order,
        total: state.home.itcCampaigns.count,
        searchKey: state.home.itcCampaigns.searchKey,
    };
};

const actionsToProps = {
    fetchITCCampaigns,
    updateITCCampaign,
};

export default connect(stateToProps, actionsToProps)(ITCCampaignsTable);
