import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
} from '../constants/accessToken';
import Axios from 'axios';
import { USER_ACCESS_TOKEN } from '../constants/url';

const fetchAccessTokenInProgress = () => {
    return {
        type: ACCESS_TOKEN_FETCH_IN_PROGRESS,
    };
};

const fetchAccessTokenSuccess = (message) => {
    return {
        type: ACCESS_TOKEN_FETCH_SUCCESS,
        message,
    };
};

const fetchAccessTokenError = (message) => {
    return {
        type: ACCESS_TOKEN_FETCH_ERROR,
        message,
    };
};

export const fetchAccessToken = (cb) => (dispatch) => {
    dispatch(fetchAccessTokenInProgress());
    let token;
    if (localStorage.getItem('studio_user') === 'true') {
        token = localStorage.getItem('refreshToken_studioConsole');
    } else {
        token = localStorage.getItem('refreshToken_nucleusConsole');
    }
    const data = {
        refreshToken: token,
    };

    Axios.post(USER_ACCESS_TOKEN, data,
        { headers: { Accept: 'application/json' } })
        .then((res) => {
            if (localStorage.getItem('studio_user') === 'true') {
                localStorage.setItem('authorizationToken_studioConsole', res.data.result['access_token']);
            } else {
                localStorage.setItem('authorizationToken_nucleusConsole', res.data.result['access_token']);
            }

            dispatch(fetchAccessTokenSuccess('Success'));
            cb(null);
        })
        .catch((error) => {
            dispatch(fetchAccessTokenError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(error);
        });
};
