import React from 'react';
import Drawer from '@mui/material/Drawer';
import * as PropTypes from 'prop-types';
import { hideCampaignQuickView } from '../../../../actions/campaign';
import { connect } from 'react-redux';
import OutsideClick from 'detect-outside-click-react';
import moment from 'moment/moment';
import { getAssetType, getAssetTypeExtension } from '../../../../utils/strings';
import VideoOnLoad from '../../../../components/ImageOnLoad/VideoOnLoad';
import ImageOnLoad from '../../../../components/ImageOnLoad/BackgroundImage';
import thumbnail from '../../../../assets/thumbnail.svg';
import './index.css';
import '../../index.css';

const Quickview = (props) => {
    return (
        <Drawer
            anchor="right"
            className="campaign_drawer"
            open={props.open}
            variant="persistent"
            onClose={() => props.hideCampaignQuickView()}
            onEscapeKeyDown={() => props.hideCampaignQuickView()}
        >
            <OutsideClick close={() => props.hideCampaignQuickView()}>
                <div className="drawer_list">
                    <div>
                        <span>Name</span>
                        <p>{props.value && props.value.name}</p>
                    </div>
                    <div>
                        <span>Description</span>
                        <p>{props.value && props.value.description}</p>
                    </div>
                    <div>
                        <span>Max USD Price</span>
                        <p>{props.value && props.value.max_usd_price}</p>
                    </div>
                    {props.value && props.value.start_date &&
                        <div>
                            <span>Start Date</span>
                            <p>{props.value && moment(props.value.start_date).format('MMM Do \'YY')}</p>
                        </div>}
                    {props.value && props.value.end_date &&
                        <div>
                            <span>End Date</span>
                            <p>{props.value && moment(props.value.end_date).format('MMM Do \'YY')}</p>
                        </div>}
                    <div className="denom_section">
                        <span>Denoms</span>
                    </div>
                    <div className="denoms_map">
                        {props.value && props.value.denoms &&
                            props.value.denoms.map((item, index) => {
                                const image = item && item.preview_uri ? item.preview_uri : thumbnail;
                                const mediaType = item && item.media_type;
                                return (
                                    <div key={index}>
                                        <React.Fragment>
                                            {mediaType &&
                                                getAssetType(mediaType) === 'video'
                                                ? <div
                                                    className="collection_icon inline_video_section">
                                                    <VideoOnLoad
                                                        src={image}
                                                        type={mediaType}/>
                                                </div>
                                                : mediaType &&
                                                    getAssetType(mediaType) === 'image' && getAssetTypeExtension(mediaType) === 'gif'
                                                    ? <ImageOnLoad
                                                        className="collection_icon"
                                                        src={image}/>
                                                    : <ImageOnLoad
                                                        cdn={item && item.cloudflare_cdn && item.cloudflare_cdn.variants}
                                                        className="collection_icon"
                                                        src={image}/>}
                                        </React.Fragment>
                                        <p>{item.name}</p>
                                    </div>
                                );
                            },
                            )}
                    </div>
                    {props.value && props.value.launchpad_denoms && props.value.launchpad_denoms.length > 0 &&
                        <div className="denom_section">
                            <span>Launchpad Denoms</span>
                        </div>}
                    {props.value && props.value.launchpad_denoms && props.value.launchpad_denoms.length > 0 &&
                        <div className="denoms_map">
                            {props.value && props.value.launchpad_denoms &&
                                props.value.launchpad_denoms.map((item, index) => {
                                    const image = item && item.preview_uri ? item.preview_uri : thumbnail;
                                    const mediaType = item && item.media_type;
                                    return (
                                        <div key={index}>
                                            <React.Fragment>
                                                {mediaType &&
                                                    getAssetType(mediaType) === 'video'
                                                    ? <div
                                                        className="collection_icon inline_video_section">
                                                        <VideoOnLoad
                                                            src={image}
                                                            type={mediaType}/>
                                                    </div>
                                                    : mediaType &&
                                                        getAssetType(mediaType) === 'image' && getAssetTypeExtension(mediaType) === 'gif'
                                                        ? <ImageOnLoad
                                                            className="collection_icon"
                                                            src={image}/>
                                                        : <ImageOnLoad
                                                            cdn={item && item.cloudflare_cdn && item.cloudflare_cdn.variants}
                                                            className="collection_icon"
                                                            src={image}/>}
                                            </React.Fragment>
                                            <p>{item.name}</p>
                                        </div>
                                    );
                                },
                                )}
                        </div>
                    }
                </div>
            </OutsideClick>
        </Drawer>
    );
};

Quickview.propTypes = {
    hideCampaignQuickView: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.campaign.campaignQuickView.open,
        value: state.campaign.campaignQuickView.value,
    };
};

const actionsToProps = {
    hideCampaignQuickView,
};

export default connect(stateToProps, actionsToProps)(Quickview);
