import { combineReducers } from 'redux';
import {
    CAMPAIGN_DENOMS_SET,
    CAMPAIGN_DESCRIPTION_SET,
    CAMPAIGN_END_DATE_SET,
    CAMPAIGN_LAUNCHPAD_DENOMS_SET,
    CAMPAIGN_LIST_ADD_ERROR,
    CAMPAIGN_LIST_ADD_IN_PROGRESS,
    CAMPAIGN_LIST_ADD_SUCCESS,
    CAMPAIGN_LIST_DIALOG_HIDE,
    CAMPAIGN_LIST_DIALOG_SHOW,
    CAMPAIGN_NAME_SET,
    CAMPAIGN_PRICE_SET,
    CAMPAIGN_QUICK_VIEW_HIDE,
    CAMPAIGN_QUICK_VIEW_SHOW,
    CAMPAIGN_START_DATE_SET,
    CAMPAIGNS_DELETE_ERROR,
    CAMPAIGNS_DELETE_IN_PROGRESS,
    CAMPAIGNS_DELETE_SUCCESS,
    CAMPAIGNS_FETCH_ERROR,
    CAMPAIGNS_FETCH_IN_PROGRESS,
    CAMPAIGNS_FETCH_SUCCESS,
    CAMPAIGNS_UPDATE_ERROR,
    CAMPAIGNS_UPDATE_IN_PROGRESS,
    CAMPAIGNS_UPDATE_SUCCESS,
    DELETE_CAMPAIGN_DIALOG_HIDE,
    DELETE_CAMPAIGN_DIALOG_SHOW,
    DENOMS_FETCH_ERROR,
    DENOMS_FETCH_IN_PROGRESS,
    DENOMS_FETCH_SUCCESS,
    LAUNCHPAD_DENOMS_FETCH_ERROR,
    LAUNCHPAD_DENOMS_FETCH_IN_PROGRESS,
    LAUNCHPAD_DENOMS_FETCH_SUCCESS,
    TAB_VALUE_SET,
} from '../constants/campaign';
import { DEFAULT_COUNT, DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY } from '../config';

const campaignsList = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGNS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const tabValue = (state = 'collections', action) => {
    if (action.type === TAB_VALUE_SET) {
        return action.value;
    }

    return state;
};

const campaignListDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CAMPAIGN_LIST_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CAMPAIGN_LIST_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const nameValue = (state = '', action) => {
    if (action.type === CAMPAIGN_NAME_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        return (action.value && action.value.name) || '';
    }

    return state;
};

const descriptionValue = (state = '', action) => {
    if (action.type === CAMPAIGN_DESCRIPTION_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        return (action.value && action.value.description) || '';
    }

    return state;
};

const startDate = (state = null, action) => {
    if (action.type === CAMPAIGN_START_DATE_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        return (action.value && action.value.start_date) || null;
    }

    return state;
};

const endDate = (state = null, action) => {
    if (action.type === CAMPAIGN_END_DATE_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        return (action.value && action.value.end_date) || null;
    }

    return state;
};

const priceValue = (state = null, action) => {
    if (action.type === CAMPAIGN_PRICE_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        return (action.value && action.value.max_usd_price) || null;
    }

    return state;
};

const denoms = (state = [], action) => {
    if (action.type === CAMPAIGN_DENOMS_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        const array = [];
        action.value && action.value.denoms && action.value.denoms.map((val) => {
            const obj = {
                value: val._id,
                label: val.name,
            };
            array.push(obj);

            return null;
        });

        return array;
    }

    return state;
};

const launchPadDenoms = (state = [], action) => {
    if (action.type === CAMPAIGN_LAUNCHPAD_DENOMS_SET) {
        return action.value;
    } else if (action.type === CAMPAIGN_LIST_DIALOG_SHOW) {
        const array = [];
        action.value && action.value.launchpad_denoms && action.value.launchpad_denoms.map((val) => {
            const obj = {
                value: val._id,
                label: val.name,
            };
            array.push(obj);

            return null;
        });

        return array;
    }

    return state;
};

const denomsList = (state = {
    inProgress: false,
    list: [],
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    searchKey: '',
    fullList: [],
}, action) => {
    switch (action.type) {
    case DENOMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DENOMS_FETCH_SUCCESS: {
        const fullList = state.fullList;
        if (action.items && action.items.length) {
            action.items.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.items,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                count: action.count,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.items],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        }
    }
    case DENOMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const addCampaign = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CAMPAIGN_LIST_ADD_IN_PROGRESS:
    case CAMPAIGNS_UPDATE_IN_PROGRESS:
    case CAMPAIGNS_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGN_LIST_ADD_SUCCESS:
    case CAMPAIGNS_UPDATE_SUCCESS:
    case CAMPAIGNS_DELETE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CAMPAIGN_LIST_ADD_ERROR:
    case CAMPAIGNS_UPDATE_ERROR:
    case CAMPAIGNS_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const campaignQuickView = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CAMPAIGN_QUICK_VIEW_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CAMPAIGN_QUICK_VIEW_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const deleteCampaignDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DELETE_CAMPAIGN_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_CAMPAIGN_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const launchpadDenomsList = (state = {
    inProgress: false,
    list: [],
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    searchKey: '',
    fullList: [],
}, action) => {
    switch (action.type) {
    case LAUNCHPAD_DENOMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LAUNCHPAD_DENOMS_FETCH_SUCCESS: {
        const fullList = state.fullList;
        if (action.items && action.items.length) {
            action.items.map((val) => {
                if (val && val._id) {
                    const index = fullList.findIndex((newVal) => newVal._id === val._id);

                    if (index === -1) {
                        fullList.push(val);
                    }
                }

                return null;
            });
        }

        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                list: action.items,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                count: action.count,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                list: [...state.list, ...action.items],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
                searchKey: action.searchKey,
                fullList: fullList,
            };
        }
    }
    case LAUNCHPAD_DENOMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    campaignsList,
    tabValue,
    campaignListDialog,
    nameValue,
    descriptionValue,
    startDate,
    endDate,
    priceValue,
    denoms,
    launchPadDenoms,
    denomsList,
    addCampaign,
    campaignQuickView,
    deleteCampaignDialog,
    launchpadDenomsList,
});
