export const IV_LIST_FETCH_SUCCESS = 'IV_LIST_FETCH_SUCCESS';
export const IV_LIST_FETCH_IN_PROGRESS = 'IV_LIST_FETCH_IN_PROGRESS';
export const IV_LIST_FETCH_ERROR = 'IV_LIST_FETCH_ERROR';

export const IV_DATA_UPDATE_SUCCESS = 'IV_DATA_UPDATE_SUCCESS';
export const IV_DATA_UPDATE_IN_PROGRESS = 'IV_DATA_UPDATE_IN_PROGRESS';
export const IV_DATA_UPDATE_ERROR = 'IV_DATA_UPDATE_ERROR';

export const IS_BANNER_POSITION_SET = 'IS_BANNER_POSITION_SET';
export const IS_BANNER_POSITION_DIALOG_SHOW = 'IS_BANNER_POSITION_DIALOG_SHOW';
export const IS_BANNER_POSITION_DIALOG_HIDE = 'IS_BANNER_POSITION_DIALOG_HIDE';
export const SHOW_BANNER_SET = 'SHOW_BANNER_SET';

export const IS_TRENDING_POSITION_SET = 'IS_TRENDING_POSITION_SET';
export const IS_TRENDING_POSITION_DIALOG_SHOW = 'IS_TRENDING_POSITION_DIALOG_SHOW';
export const IS_TRENDING_POSITION_DIALOG_HIDE = 'IS_TRENDING_POSITION_DIALOG_HIDE';
