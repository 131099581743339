import React from 'react';
import * as PropTypes from 'prop-types';
import { fetchCampaigns, setTabValue } from '../../../actions/campaign';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { fetchCollections, fetchITCCampaigns, fetchBanners } from '../../../actions/home';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';

const Tabs = (props) => {
    const handleChange = (newValue) => {
        props.setTabValue(newValue);

        if (newValue === 'collections' && props.collections &&
            !props.collections.length && !props.collectionsInProgress) {
            props.fetchCollections(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc', '', props.showFeatured);
        } else if (newValue === 'campaigns' && props.campaignList &&
            !props.campaignList.length && !props.campaignInProgress) {
            props.fetchCampaigns(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
        } else if (newValue === 'itc_campaigns' && props.itcCampaigns &&
            !props.itcCampaigns.length && !props.itcCampaignsInProgress) {
            props.fetchITCCampaigns(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
        } else if (newValue === 'banners' && props.bannersList &&
            !props.bannersList.length && !props.bannerInProgress) {
            props.fetchBanners();
        }
    };
    // app.put('/itc-campaigns/:id', isAdmin,
    //     validation.updateCampaign, controller.updateCampaign);
    return (
        <div className="tabs">
            <Button
                className={props.value === 'collections' ? 'active_tab' : ''}
                onClick={() => handleChange('collections')}>
                Collections
            </Button>
            <Button
                className={props.value === 'campaigns' ? 'active_tab' : ''}
                onClick={() => handleChange('campaigns')}>
                Curations
            </Button>
            <Button
                className={props.value === 'itc_campaigns' ? 'active_tab' : ''}
                onClick={() => handleChange('itc_campaigns')}>
                ITC Campaigns
            </Button>
            <Button
                className={props.value === 'banners' ? 'active_tab' : ''}
                onClick={() => handleChange('banners')}>
                Banners
            </Button>
        </div>
    );
};

Tabs.propTypes = {
    bannerInProgress: PropTypes.bool.isRequired,
    bannersList: PropTypes.array.isRequired,
    campaignInProgress: PropTypes.bool.isRequired,
    campaignList: PropTypes.array.isRequired,
    collections: PropTypes.array.isRequired,
    collectionsInProgress: PropTypes.bool.isRequired,
    fetchBanners: PropTypes.func.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
    fetchCollections: PropTypes.func.isRequired,
    fetchITCCampaigns: PropTypes.func.isRequired,
    itcCampaigns: PropTypes.array.isRequired,
    itcCampaignsInProgress: PropTypes.bool.isRequired,
    setTabValue: PropTypes.func.isRequired,
    showFeatured: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.campaign.tabValue,

        campaignList: state.campaign.campaignsList.list,
        campaignInProgress: state.campaign.campaignsList.inProgress,

        collections: state.home.collections.list,
        collectionsInProgress: state.home.collections.inProgress,
        showFeatured: state.home.showFeatured,

        itcCampaigns: state.home.itcCampaigns.list,
        itcCampaignsInProgress: state.home.itcCampaigns.inProgress,

        bannersList: state.home.bannersList.list,
        bannerInProgress: state.home.bannersList.inProgress,
    };
};

const actionToProps = {
    setTabValue,
    fetchCampaigns,
    fetchCollections,
    fetchITCCampaigns,
    fetchBanners,
};

export default connect(stateToProps, actionToProps)(Tabs);
