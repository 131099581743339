import Table from './Table';
import React from 'react';
import './index.css';

const Campaign = () => {
    return (
        <div className="collection_table campaign_table">
            <Table/>
        </div>
    );
};

export default Campaign;
