import React from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { fetchCollections, hideFeaturedPositionDialog, updateCollection } from '../../../../actions/home';
import { connect } from 'react-redux';
import PositionTextField from './PositionTextField';

const MintAccessDialog = (props) => {
    const handleClick = () => {
        const id = props.value && props.value._id;
        const data = {
            featuredPosition: Number(props.position),
            isFeatured: true,
        };

        props.updateCollection(id, data, (cb) => {
            if (!cb) {
                props.fetchCollections(props.skip, props.limit, props.sortBy, props.order, props.searchKey, props.showFeatured);
                props.hideFeaturedPositionDialog();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog position_dialog"
            open={props.open}
            onClose={props.hideFeaturedPositionDialog}>
            <div className="mint_access">
                <h2> Featured Position </h2>
                <PositionTextField/>
                <Button disabled={props.position === ''} onClick={handleClick}>
                    Confirm
                </Button>
            </div>
        </Dialog>
    );
};

MintAccessDialog.propTypes = {
    fetchCollections: PropTypes.func.isRequired,
    hideFeaturedPositionDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    showFeatured: PropTypes.bool.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    updateCollection: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    order: PropTypes.string,
    position: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.featuredPositionDialog.open,
        value: state.home.featuredPositionDialog.value,
        position: state.home.featuredPosition,

        skip: state.home.collections.skip,
        limit: state.home.collections.limit,
        sortBy: state.home.collections.sortBy,
        order: state.home.collections.order,
        total: state.home.collections.count,
        searchKey: state.home.collections.searchKey,
        showFeatured: state.home.showFeatured,
    };
};

const actionsToProps = {
    hideFeaturedPositionDialog,

    updateCollection,
    fetchCollections,
};

export default connect(stateToProps, actionsToProps)(MintAccessDialog);
