import React from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import moment from 'moment';
import {
    fetchCollections,
    setLaunchpadUrl,
    showFeaturedPositionDialog,
    showLaunchpadDialog,
    updateCollection,
} from '../../../actions/home';
import { getAssetType, getAssetTypeExtension } from '../../../utils/strings';
import VideoOnLoad from '../../../components/ImageOnLoad/VideoOnLoad';
import ImageOnLoad from '../../../components/ImageOnLoad/BackgroundImage';
import { Button, Tooltip } from '@material-ui/core';
import thumbnail from '../../../assets/thumbnail.svg';
import CopyButton from '../../../components/CopyButton';
import Icon from '../../../components/Icon';

const CollectionsTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCollections(props.skip, props.limit, props.sortBy, props.order, searchText, props.showFeatured);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCollections(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey, props.showFeatured);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCollections(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey, props.showFeatured);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            // if (changedColumn === 'isFeatured' && direction === 'desc') {
            //     props.fetchCollections(props.skip, props.limit, changedColumn, direction, props.searchKey, 'isFeatured');
            // } else {
            props.fetchCollections(props.skip, props.limit, changedColumn, direction, props.searchKey, props.showFeatured);
            // }
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
    };

    const handleCollection = (collection, section, value) => {
        const id = collection && collection._id;
        let data = {};
        if (section === 'genesisCreator') {
            data = {
                genesisCreator: value,
            };
        } else if (section === 'ipInfringement') {
            data = {
                ipInfringement: value,
            };
        } else if (section === 'isFeatured') {
            data = {
                isFeatured: false,
            };
        }

        props.updateCollection(id, data, (cb) => {
            if (!cb) {
                props.fetchCollections(props.skip, props.limit, props.sortBy, props.order, props.searchKey, props.showFeatured);
            }
        });
    };

    const handleLaunchpad = (value) => {
        props.showLaunchpadDialog(value);
        if (value && value.app_url) {
            props.setLaunchpadUrl(value.app_url);
        }
    };

    const columns = [{
        name: 'image',
        label: 'Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const image = value && value.preview_uri ? value.preview_uri : thumbnail;
                const mediaType = value && value.media_type;

                return (
                    <React.Fragment>
                        {mediaType &&
                        getAssetType(mediaType) === 'video'
                            ? <div
                                className="collection_icon inline_video_section">
                                <VideoOnLoad
                                    src={image}
                                    type={mediaType}/>
                            </div>
                            : mediaType &&
                            getAssetType(mediaType) === 'image' && getAssetTypeExtension(mediaType) === 'gif'
                                ? <ImageOnLoad
                                    className="collection_icon"
                                    src={image}/>
                                : <ImageOnLoad
                                    cdn={value && value.cloudflare_cdn && value.cloudflare_cdn.variants}
                                    className="collection_icon"
                                    src={image}/>}
                    </React.Fragment>
                );
            },
        },
    }, {
        name: 'name',
        label: 'Collection Name',
        options: {
            customBodyRender: function (value) {
                const name = value.name && (value.name || value.symbol);
                return (
                    <Tooltip title={value.name + ' | ' + value.symbol}>
                        <div
                            className="collection_name">
                            {name}
                        </div>
                    </Tooltip>
                );
            },
        },
    }, {
        name: 'id',
        label: 'Collection ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="to_value tx_hash creator_address">
                        <div className="hash_text" title={value}>
                            <p className="name">{value}</p>
                            {value && value.slice(value.length - 6, value.length)}
                        </div>
                        <CopyButton data={value} />
                    </div>
                );
            },
        },
    }, {
        name: 'creator',
        label: 'Creator',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div
                        className="to_value tx_hash creator_address">
                        <div className="hash_text" title={value}>
                            <p className="name">{value}</p>
                            {value && value.slice(value.length - 6, value.length)}
                        </div>
                    </div>
                );
            },
        },
    }, {
        name: 'total_nfts',
        label: 'Total NFTs',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'unique_owners',
        label: 'Unique Owners',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'total_lists',
        label: 'Listed NFTs',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'verified',
        label: 'Verified',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'IP_infringement',
        label: 'IP Infringement',
        options: {
            customBodyRender: function (value) {
                return (
                    <div>
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'isFeatured',
        label: 'Featured',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const featured = value.featured ? 'True' : 'False';
                const number = value.featured_position;
                return (
                    <div className="is_featured">
                        {featured}
                        {featured === 'True' && ' (' + (number) + ')'}
                        {featured === 'True' && value.featured &&
                            <Button onClick={() => props.showFeaturedPositionDialog(value)}>
                                <Icon className="edit" icon="edit" />
                            </Button>}
                    </div>
                );
            },
        },
    }, {
        name: 'created_at',
        label: 'Created Date',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value && moment(value).format('MMM Do \'YY')}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        {value && value.verified
                            ? <Button onClick={() => handleCollection(value, 'genesisCreator', false)}>
                                Un-Verify
                            </Button>
                            : <Button onClick={() => handleCollection(value, 'genesisCreator', true)}>
                                Verify
                            </Button>}
                        {value && value.IP_infringement
                            ? <Button onClick={() => handleCollection(value, 'ipInfringement', false)}>
                                Un-Tag Infringement
                            </Button>
                            : <Button onClick={() => handleCollection(value, 'ipInfringement', true)}>
                                Tag Infringement
                            </Button>}
                        {value && value.on_launchpad
                            ? <Button
                                className="primary_button"
                                onClick={() => handleLaunchpad(value)}>
                                Update Launchpad
                            </Button>
                            : <Button
                                className="primary_button"
                                onClick={() => handleLaunchpad(value)}>
                                Add to Launchpad
                            </Button>}
                        {value && value.featured
                            ? <Button onClick={() => handleCollection(value, 'isFeatured', false)}>
                                Un Feature
                            </Button>
                            : <Button onClick={() => props.showFeaturedPositionDialog(value)}>
                                Feature
                            </Button>}
                    </div>

                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item,
                item,
                item.id,
                item.creator,
                item.total_nfts,
                item.unique_owners,
                item.total_lists,
                item.verified ? 'True' : 'False',
                item.IP_infringement ? 'True' : 'False',
                item,
                item.created_at ? item.created_at : '-',
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                // name={'Mint Requests'}
                options={options}/>
        </>
    );
};

CollectionsTable.propTypes = {
    fetchCollections: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    setLaunchpadUrl: PropTypes.func.isRequired,
    showFeatured: PropTypes.bool.isRequired,
    showFeaturedPositionDialog: PropTypes.func.isRequired,
    showLaunchpadDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    updateCollection: PropTypes.func.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.collections.list,
        inProgress: state.home.collections.inProgress,
        skip: state.home.collections.skip,
        limit: state.home.collections.limit,
        sortBy: state.home.collections.sortBy,
        order: state.home.collections.order,
        total: state.home.collections.count,
        searchKey: state.home.collections.searchKey,
        showFeatured: state.home.showFeatured,
    };
};

const actionsToProps = {
    fetchCollections,
    updateCollection,
    setLaunchpadUrl,
    showLaunchpadDialog,
    showFeaturedPositionDialog,
};

export default connect(stateToProps, actionsToProps)(CollectionsTable);
