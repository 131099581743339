const variables = {
    en: {
        refund_status: 'Refund Status',
        enter_address: 'Enter Address',
        invalid_address: 'Invalid Address',
        enter_url: 'Enter APP Url',
        enter_denom_id: 'Enter Collection ID',
        upload_image: 'Upload Image',
        re_upload: 'Re-Upload',

        enter_name: 'Enter Name',
        enter_description: 'Enter Description',
        enter_price: 'Enter Price',
        start_date: 'Start Date',
        end_date: 'End Date',
        enter_denoms: 'Enter Denoms',
        enter_launchpad_denoms: 'Enter Launchpad Denoms',
        enter_web_image_url: 'Enter Web Image URL',
        enter_mob_image_url: 'Enter Mobile Image URL',
        enter_tab_image_url: 'Enter Tab Image URL',
        enter_large_screen_image_url: 'Enter Large Screen Image URL',
        mobile_image: 'Mobile image',
        web_image: 'Web Image',
        tab_image: 'Tab Image',
        large_screnn_image: 'Large Screen Image',
        enter_redirection_url: 'Enter Redirection URL',

        enter_position: 'Enter Position',
    },
};

export default variables;
