import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import variables from '../../../../../utils/variables';
import TextField from '../../../../../components/TextField';
import { setLargeScreenImageUrl, largeScreenImageUpload } from '../../../../../actions/home';
import DotsLoading from '../../../../../components/DotsLoading';
import { Button } from '@material-ui/core';
import { showMessage } from '../../../../../actions/snackbar';
import { mediaReference } from '../../../../../utils/ipfs';
import { getAssetType } from '../../../../../utils/strings';
import ClassName from 'classnames';
import ImageOnLoad from '../../../../../components/ImageOnLoad/BackgroundImage';

const LargeScreenImageField = (props) => {
    const handleTextChange = (value) => {
        props.setLargeScreenImageUrl(value);
    };

    const handleFileChange = (selectedFiles) => {
        const type = selectedFiles && selectedFiles.length && selectedFiles[0] && getAssetType(selectedFiles[0].type);
        if (['image'].indexOf(type) === -1) {
            props.showMessage('File format not supported', 'warning');
            return;
        }

        if (selectedFiles && selectedFiles.length > 0 && selectedFiles[0]) {
            const image = new Image();
            image.addEventListener('load', () => {
                props.largeScreenImageUpload(selectedFiles[0], (res) => {
                    if (res) {
                        const url = mediaReference(res.Hash);
                        props.setLargeScreenImageUrl(url);
                    }
                });
            });
            image.src = URL.createObjectURL(selectedFiles[0]);
        } else {
            props.showMessage('File Not Selected', 'warning');
        }
    };

    return (
        <>
            <TextField
                className="image_url"
                id="image_url"
                name="image_url"
                placeholder={variables[props.lang]['enter_large_screen_image_url']}
                value={props.imageUrl}
                onChange={handleTextChange}/>
            {props.imageInProgress
                ? <DotsLoading/>
                : <div
                    className={ClassName('file_upload', props.imageUrl ? 're_upload' : '')}
                    onDrop={(e) => {
                        e.preventDefault();
                        handleFileChange(e.dataTransfer.files);
                    }}>
                    <Button
                        variant="contained">
                        <input
                            accept={'image/*'}
                            type="file"
                            onChange={(e) =>
                                handleFileChange(e.target.files)
                            }/>
                        {props.imageUrl
                            ? <div className="re_upload_div">
                                <p>{variables[props.lang]['re_upload']}</p>
                                <ImageOnLoad className="uploaded_image" preview={props.imageUrl} />
                            </div>
                            : variables[props.lang]['upload_image']}
                    </Button>
                </div>}
        </>
    );
};

LargeScreenImageField.propTypes = {
    imageInProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    largeScreenImageUpload: PropTypes.func.isRequired,
    setLargeScreenImageUrl: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    imageUrl: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        imageUrl: state.home.bannerLargeScreenImageValue,
        imageInProgress: state.home.largeScreenImage.inProgress,
    };
};

const actionToProps = {
    setLargeScreenImageUrl,
    showMessage,
    largeScreenImageUpload,
};

export default connect(stateToProps, actionToProps)(LargeScreenImageField);
