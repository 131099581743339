import React from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PositionTextField from './PositionTextField';
import {
    fetchIVList,
    hideTrendingPositionDialog,
    updateIVData,
} from '../../../../actions/studio';

const MintAccessDialog = (props) => {
    const handleClick = () => {
        const id = props.value && props.value._id;
        const data = {
            trendingPosition: Number(props.position),
            isTrending: true,
        };

        props.updateIVData(id, data, (cb) => {
            if (!cb) {
                props.fetchIVList(props.ivListSkip, props.ivListLimit, props.ivListSortBy, props.ivListOrder, props.ivListSearchKey);
                props.hideTrendingPositionDialog();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog position_dialog"
            open={props.open}
            onClose={props.hideTrendingPositionDialog}>
            <div className="mint_access">
                <h2> Trending Position </h2>
                <PositionTextField/>
                <Button disabled={props.position === ''} onClick={handleClick}>
                    Confirm
                </Button>
            </div>
        </Dialog>
    );
};

MintAccessDialog.propTypes = {
    fetchIVList: PropTypes.func.isRequired,
    hideTrendingPositionDialog: PropTypes.func.isRequired,
    ivListInProgress: PropTypes.bool.isRequired,
    ivListSortBy: PropTypes.string.isRequired,
    ivLists: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    position: PropTypes.string.isRequired,
    updateIVData: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    ivListLimit: PropTypes.number,
    ivListOrder: PropTypes.string,
    ivListSearchKey: PropTypes.string,
    ivListSkip: PropTypes.number,
    ivListTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.studio.trendingPositionDialog.open,
        value: state.studio.trendingPositionDialog.value,
        position: state.studio.trendingPosition,

        ivLists: state.studio.ivListData.list,
        ivListInProgress: state.studio.ivListData.inProgress,
        ivListSkip: state.studio.ivListData.skip,
        ivListLimit: state.studio.ivListData.limit,
        ivListSortBy: state.studio.ivListData.sortBy,
        ivListOrder: state.studio.ivListData.order,
        ivListTotal: state.studio.ivListData.count,
        ivListSearchKey: state.studio.ivListData.searchKey,
    };
};

const actionsToProps = {
    hideTrendingPositionDialog,

    updateIVData,
    fetchIVList,
};

export default connect(stateToProps, actionsToProps)(MintAccessDialog);
