import { Button, Card } from '@material-ui/core';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { loginStudioUser, loginUser } from '../../actions/login';
import EmailTextField from './EmailTextField';
import './index.css';
import PasswordTextField from './PasswordTextField';
import CircularProgress from '../../components/CircularProgress';
import { fetchAccessToken } from '../../actions/accessToken';
import withRouter from '../../components/WithRouter';
import SwitchButton from './SwitchButton';

const LoginPage = (props) => {
    const characterCode = (event) => {
        if (event.keyCode === 13) {
            onClick();
        }
    };

    const onClick = () => {
        if (props.inProgress) {
            return;
        }
        if (props.switch) {
            props.loginStudioUser(props.email, props.password, (cb) => {
                if (cb) {
                    return;
                }

                const token = localStorage.getItem('authorizationToken_studioConsole');
                if (token) {
                    props.router.navigate('/home');
                } else {
                    props.router.navigate('/login');
                }

                if (token) {
                    const decoded = jwt.decode(token);
                    const difference = moment(new Date((decoded.exp) * 1000)).diff(moment(new Date()));
                    const nextCallTime = difference - 300000;
                    const refreshToken = localStorage.getItem('refreshToken_studioConsole');
                    setTimeout(() => {
                        props.fetchAccessToken(refreshToken, () => {
                        });
                    }, nextCallTime);
                }
            });
        } else {
            props.onClick(props.email, props.password, (cb) => {
                if (cb) {
                    return;
                }

                const token = localStorage.getItem('authorizationToken_nucleusConsole');
                if (token) {
                    props.router.navigate('/home');
                } else {
                    props.router.navigate('/login');
                }

                if (token) {
                    const decoded = jwt.decode(token);
                    const difference = moment(new Date((decoded.exp) * 1000)).diff(moment(new Date()));
                    const nextCallTime = difference - 300000;
                    const refreshToken = localStorage.getItem('refreshToken_nucleusConsole');
                    setTimeout(() => {
                        props.fetchAccessToken(refreshToken, () => {
                        });
                    }, nextCallTime);
                }
            });
        }
    };

    const disable = props.email === '' || props.password === '';
    return (
        <div className="login">
            <div className="card_div">
                <Card className="card_login">
                    <h2 style={{ color: '#fff' }}> Log In</h2>
                    <form onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            onClick(ev);
                        }
                    }}>
                        <div className="fields_section">
                            <EmailTextField/>
                            <div className="password_remember">
                                <PasswordTextField/>
                            </div>
                        </div>
                        <div>
                            <SwitchButton />
                        </div>
                        <div className="login_action">
                            {
                                props.inProgress
                                    ? <Button
                                        className={disable ? 'action_button disabled_button' : 'action_button'}
                                        color="primary"
                                        disabled={disable}
                                        onClick={() => onClick()}
                                        onKeyDown={(event) => characterCode(event)}>
                                        <CircularProgress/>
                                    </Button>
                                    : <Button
                                        className={disable ? 'action_button disabled_button' : 'action_button'}
                                        color="primary"
                                        disabled={disable}
                                        onClick={onClick}
                                        onKeyDown={(event) => characterCode(event)}>
                                        SIGN IN
                                    </Button>
                            }
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );
};

LoginPage.propTypes = {
    email: PropTypes.object.isRequired,
    fetchAccessToken: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    loginStudioUser: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
    switch: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        navigate: PropTypes.func.isRequired,
        params: PropTypes.shape({
            campaignName: PropTypes.string,
            campaignID: PropTypes.string,
        }).isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        email: state.login.email,
        password: state.login.password,
        inProgress: state.login.inProgress,
        switch: state.login.loginSwitch.value,
    };
};

const actionsToProps = {
    onClick: loginUser,
    loginStudioUser,
    fetchAccessToken,
};

export default withRouter(connect(stateToProps, actionsToProps)(LoginPage));
