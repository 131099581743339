import { IV_LIST_URL, urlIVUpdate } from '../constants/url';
import Axios from 'axios';
import {
    IS_BANNER_POSITION_DIALOG_HIDE,
    IS_BANNER_POSITION_DIALOG_SHOW,
    IS_BANNER_POSITION_SET,
    IS_TRENDING_POSITION_DIALOG_HIDE,
    IS_TRENDING_POSITION_DIALOG_SHOW,
    IS_TRENDING_POSITION_SET,
    IV_DATA_UPDATE_ERROR,
    IV_DATA_UPDATE_IN_PROGRESS,
    IV_DATA_UPDATE_SUCCESS,
    IV_LIST_FETCH_ERROR,
    IV_LIST_FETCH_IN_PROGRESS,
    IV_LIST_FETCH_SUCCESS,
    SHOW_BANNER_SET,
} from '../constants/studio';

export const setShowBanner = (value) => {
    return {
        type: SHOW_BANNER_SET,
        value,
    };
};

export const showBannerPositionDialog = (value) => {
    return {
        type: IS_BANNER_POSITION_DIALOG_SHOW,
        value,
    };
};

export const hideBannerPositionDialog = () => {
    return {
        type: IS_BANNER_POSITION_DIALOG_HIDE,
    };
};

export const setBannerPosition = (value) => {
    return {
        type: IS_BANNER_POSITION_SET,
        value,
    };
};

export const showTrendingPositionDialog = (value) => {
    return {
        type: IS_TRENDING_POSITION_DIALOG_SHOW,
        value,
    };
};

export const hideTrendingPositionDialog = () => {
    return {
        type: IS_TRENDING_POSITION_DIALOG_HIDE,
    };
};

export const setTrendingPosition = (value) => {
    return {
        type: IS_TRENDING_POSITION_SET,
        value,
    };
};

const fetchIVListInProgress = () => {
    return {
        type: IV_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchIVListSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: IV_LIST_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchIVListError = (message) => {
    return {
        type: IV_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchIVList = (skip, limit, sortBy, order, searchKey, banner) => (dispatch) => {
    dispatch(fetchIVListInProgress());

    const url = IV_LIST_URL(skip, limit, sortBy, order, searchKey, banner);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchIVListSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchIVListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateIVDataInProgress = () => {
    return {
        type: IV_DATA_UPDATE_IN_PROGRESS,
    };
};

const updateIVDataSuccess = (value, message) => {
    return {
        type: IV_DATA_UPDATE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateIVDataError = (message) => {
    return {
        type: IV_DATA_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateIVData = (id, data, cb) => (dispatch) => {
    dispatch(updateIVDataInProgress());

    const url = urlIVUpdate(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_studioConsole'),
        },
    })
        .then((res) => {
            dispatch(updateIVDataSuccess(res.data && res.data.result, 'Collection Tag Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateIVDataError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};
