import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../../components/TextField';
import variables from '../../../../utils/variables';
import { setLaunchpadUrl } from '../../../../actions/home';

const LaunchpadUrlTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="launchpad_url"
            id="launchpad-url"
            name="launchpad"
            placeholder={variables[props.lang]['enter_url']}
            value={props.value}
            onChange={handleChange}/>
    );
};

LaunchpadUrlTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.home.launchpad.url,
    };
};

const actionsToProps = {
    onChange: setLaunchpadUrl,
};

export default connect(stateToProps, actionsToProps)(LaunchpadUrlTextField);
