import React from 'react';
import './index.css';
import '../MintAccessDialog/index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { fetchCollection, fetchCollections, hideLaunchpadDialog, updateCollection } from '../../../../actions/home';
import LaunchpadUrl from './LaunchpadUrl';
import { connect } from 'react-redux';
import CircularProgress from '../../../../components/CircularProgress';

const LaunchpadDialog = (props) => {
    const handleAdd = () => {
        const data = {
            onLaunchpad: true,
            appURL: props.url,
        };

        props.updateCollection(props.data && props.data._id, data, (error) => {
            if (!error) {
                props.fetchCollections(props.skip, props.limit, props.sortBy, props.order, props.searchKey, props.showFeatured);
                props.hideLaunchpadDialog();
            }
        });
    };

    const handleRemove = () => {
        const data = {
            onLaunchpad: false,
        };

        if (props.url) {
            data.appURL = props.url;
        }

        props.updateCollection(props.data && props.data._id, data, (error) => {
            if (!error) {
                props.fetchCollections(props.skip, props.limit, props.sortBy, props.order, props.searchKey, props.showFeatured);
                props.hideLaunchpadDialog();
            }
        });
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog launchpad_dialog"
            open={props.open}
            onClose={props.hideLaunchpadDialog}>
            {props.collectionInProgress || props.collectionUpdateInProgress
                ? <CircularProgress className="full_screen"/> : null}
            <div className="mint_access">
                <h2> Add Launchpad </h2>
                {/* <DenomIDTextField/> */}
                <LaunchpadUrl/>
                <div className="buttons_div">
                    {props.data && props.data.app_url && props.data.app_url !== '' &&
                    <Button onClick={handleRemove}>
                        Remove
                    </Button>}
                    <Button disabled={props.url === ''} onClick={handleAdd}>
                        {props.data && props.data.app_url && props.data.app_url !== '' &&
                        props.data.on_launchpad
                            ? 'Update'
                            : 'Add'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

LaunchpadDialog.propTypes = {
    collectionInProgress: PropTypes.bool.isRequired,
    collectionUpdateInProgress: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    denomId: PropTypes.string.isRequired,
    fetchCollection: PropTypes.func.isRequired,
    fetchCollections: PropTypes.func.isRequired,
    hideLaunchpadDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    showFeatured: PropTypes.bool.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    updateCollection: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        denomId: state.home.launchpad.denomId,
        url: state.home.launchpad.url,
        lang: state.language,
        open: state.home.launchpad.open,
        data: state.home.launchpad.data,
        collectionInProgress: state.home.collection.inProgress,
        collectionUpdateInProgress: state.home.collectionUpdate.inProgress,
        skip: state.home.collections.skip,
        limit: state.home.collections.limit,
        sortBy: state.home.collections.sortBy,
        order: state.home.collections.order,
        total: state.home.collections.count,
        searchKey: state.home.collections.searchKey,
        showFeatured: state.home.showFeatured,
    };
};

const actionsToProps = {
    fetchCollection,
    fetchCollections,
    hideLaunchpadDialog,
    updateCollection,
};

export default connect(stateToProps, actionsToProps)(LaunchpadDialog);
