import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import home from './home';
import login from './login';
import accessToken from './accessToken';
import campaign from './campaign';
import studio from './studio';

export default combineReducers({
    language,
    snackbar,
    home,
    login,
    accessToken,
    campaign,
    studio,
});
