import React from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteBannerList, hideDeleteBannerDialog, fetchBanners } from '../../../../../actions/home';
import { showMessage } from '../../../../../actions/snackbar';
import CircularProgress from '../../../../../components/CircularProgress';

const DeleteBannerDialog = (props) => {
    const deleteBanner = () => {
        if (props.value && props.value._id) {
            props.deleteBannerList(props.value._id, (cb) => {
                if (!cb) {
                    props.hideDeleteBannerDialog();
                    props.fetchBanners();
                }
            });
        } else {
            props.showMessage('Something went wrong!');
        }
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog banner_dialog"
            open={props.open}
            onClose={props.hideDeleteBannerDialog}>
            <div className="mint_access banner_dialog_section">
                <h2> Are you sure to delete
                    <bold>&nbsp;{props.value && props.value.name}&nbsp;</bold>
                    Banner?
                </h2>
                <Button style={{ marginTop: '50px' }} onClick={deleteBanner}>
                    {props.inProgress && <CircularProgress/>}
                    Delete Banner
                </Button>
            </div>
        </Dialog>
    );
};

DeleteBannerDialog.propTypes = {
    deleteBannerList: PropTypes.func.isRequired,
    fetchBanners: PropTypes.func.isRequired,
    hideDeleteBannerDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    showMessage: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.deleteBannerDialog.open,
        value: state.home.deleteBannerDialog.value,

        inProgress: state.home.addBanner.inProgress,
    };
};

const actionToProps = {
    showMessage,
    deleteBannerList,
    hideDeleteBannerDialog,
    fetchBanners,
};

export default connect(stateToProps, actionToProps)(DeleteBannerDialog);
