import React, { useEffect } from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    addCampaignList,
    fetchCampaigns, fetchDenoms, fetchLaunchpadDenoms,
    hideCampaignListDialog,
    updateCampaignList,
} from '../../../../../actions/campaign';
import NameTextField from './NameTextField';
import DescriptionTextField from './DescriptionTextField';
import StartDateTextField from './StartDateTextField';
import EndDateTextField from './EndDateTextField';
import PriceTextField from './PriceTextField';
import DenomsSelectField from './DenomsSelectField';
import { showMessage } from '../../../../../actions/snackbar';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../../../config';
import CircularProgress from '../../../../../components/CircularProgress';
import LaunchpadDenoms from './LaunchpadDenoms';

const AddCampaignDialog = (props) => {
    useEffect(() => {
        props.fetchDenoms(0, 1000);
        props.fetchLaunchpadDenoms(0, 1000);
    });

    const addCampaign = () => {
        const data = {};
        if (props.nameValue !== '') {
            data.name = props.nameValue;
        }
        if (props.descriptionValue !== '') {
            data.description = props.descriptionValue;
        }
        if (props.denoms && props.denoms.length > 0) {
            data.denoms = props.denoms && props.denoms.map((item) => item.value);
        }
        if (props.startDate !== null) {
            data.startDate = props.startDate;
        }
        if (props.endDate !== null) {
            data.endDate = props.endDate;
        }
        if (props.priceValue > 0) {
            data.maxUSDPrice = props.priceValue;
        }
        if (props.launchPadDenoms && props.launchPadDenoms.length > 0) {
            data.launchpadDenoms = props.launchPadDenoms && props.launchPadDenoms.map((item) => item.value);
        }
        if (props.nameValue && props.denoms && props.denoms.length > 0 && props.priceValue) {
            props.addCampaignList(data, (cb) => {
                if (!cb) {
                    props.hideCampaignListDialog();
                    props.fetchCampaigns(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
                }
            });
        } else {
            props.showMessage('Please add all fields');
        }
    };

    const editCampaign = () => {
        const data = {};
        if (props.nameValue !== '') {
            data.name = props.nameValue;
        }
        if (props.descriptionValue !== '') {
            data.description = props.descriptionValue;
        }
        if (props.denoms && props.denoms.length > 0) {
            data.denoms = props.denoms && props.denoms.map((item) => item.value);
        }
        if (props.startDate !== null) {
            data.startDate = props.startDate;
        }
        if (props.endDate !== null) {
            data.endDate = props.endDate;
        }
        if (props.priceValue > 0) {
            data.maxUSDPrice = props.priceValue;
        }
        if (props.launchPadDenoms && props.launchPadDenoms.length > 0) {
            data.launchpadDenoms = props.launchPadDenoms && props.launchPadDenoms.map((item) => item.value);
        }
        if (props.nameValue && props.denoms && props.denoms.length > 0 && props.priceValue) {
            props.updateCampaignList(data, props.value && props.value._id, (cb) => {
                if (!cb) {
                    props.hideCampaignListDialog();
                    props.fetchCampaigns(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
                }
            });
        } else {
            props.showMessage('Please add all fields');
        }
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog campaign_dialog"
            open={props.open}
            onClose={props.hideCampaignListDialog}>
            <div className="mint_access campaign_dialog_section">
                <h2> Add Campaign </h2>
                <div className="campaign_fields">
                    <NameTextField/>
                    <DescriptionTextField/>
                    <DenomsSelectField/>
                    <LaunchpadDenoms />
                    <StartDateTextField/>
                    <EndDateTextField/>
                    <PriceTextField/>
                </div>
                {props.value && props.value._id
                    ? <Button onClick={editCampaign}>
                        {props.inProgress && <CircularProgress/>}
                        Edit Campaign
                    </Button>
                    : <Button onClick={addCampaign}>
                        {props.inProgress && <CircularProgress/>}
                        Add Campaign
                    </Button>}
            </div>
        </Dialog>
    );
};

AddCampaignDialog.propTypes = {
    addCampaignList: PropTypes.func.isRequired,
    denoms: PropTypes.array.isRequired,
    descriptionValue: PropTypes.string.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
    fetchDenoms: PropTypes.func.isRequired,
    fetchLaunchpadDenoms: PropTypes.func.isRequired,
    hideCampaignListDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    launchPadDenoms: PropTypes.array.isRequired,
    nameValue: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    priceValue: PropTypes.number.isRequired,
    showMessage: PropTypes.func.isRequired,
    updateCampaignList: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.campaign.campaignListDialog.open,
        value: state.campaign.campaignListDialog.value,
        inProgress: state.campaign.addCampaign.inProgress,

        nameValue: state.campaign.nameValue,
        descriptionValue: state.campaign.descriptionValue,
        denoms: state.campaign.denoms,
        priceValue: state.campaign.priceValue,
        endDate: state.campaign.endDate,
        startDate: state.campaign.startDate,
        launchPadDenoms: state.campaign.launchPadDenoms,
    };
};

const actionsToProps = {
    hideCampaignListDialog,
    showMessage,
    addCampaignList,
    fetchCampaigns,
    updateCampaignList,
    fetchDenoms,
    fetchLaunchpadDenoms,
};

export default connect(stateToProps, actionsToProps)(AddCampaignDialog);
