import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../../../components/TextField';
import variables from '../../../../../utils/variables';
import { setCampaignDescription } from '../../../../../actions/campaign';

const NameTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="description_field"
            id="description-value"
            name="description"
            placeholder={variables[props.lang]['enter_description']}
            value={props.value}
            onChange={handleChange}/>
    );
};

NameTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.campaign.descriptionValue,
    };
};

const actionsToProps = {
    onChange: setCampaignDescription,
};

export default connect(stateToProps, actionsToProps)(NameTextField);
