import React from 'react';
import * as PropTypes from 'prop-types';
import { showLaunchpadDialog, showMintAccessDialog } from '../../../actions/home';
import withRouter from '../../../components/WithRouter';
import { connect } from 'react-redux';
import IVListTable from './IVListTable';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../../config';
import { fetchIVList, setShowBanner } from '../../../actions/studio';
import BannerPositionDialog from './BannerPositionDialog';
import TrendingPositionDialog from './TrendingPositionDialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './index.css';

class Studio extends React.Component {
    constructor (props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount () {
        if (this.props.ivLists && !this.props.ivLists.length && !this.props.ivListInProgress) {
            this.props.fetchIVList(DEFAULT_SKIP, DEFAULT_LIMIT, 'created_at', 'desc');
        }
    }

    handleClick (event) {
        if (event.target.checked) {
            this.props.fetchIVList(this.props.ivListSkip, this.props.ivListLimit, this.props.ivListSortBy, this.props.ivListOrder, this.props.ivListSearchKey, true);
        } else {
            this.props.fetchIVList(this.props.ivListSkip, this.props.ivListLimit, this.props.ivListSortBy, this.props.ivListOrder, this.props.ivListSearchKey, false);
        }

        this.props.setShowBanner(event.target.checked);
    }

    render () {
        return (
            <div className="table_div">
                <div className="header" style={{ marginBottom: '20px' }}>
                    <h2> Studio Console</h2>
                </div>
                <div className="header_section1 studio_header">
                    <div>
                        <FormControlLabel
                            className="checkbox"
                            control={
                                <Checkbox
                                    checked={this.props.showBanner}
                                    onChange={this.handleClick}
                                />
                            }
                            label="Show Banner"
                        />
                    </div>
                </div>
                <div className="collection_table">
                    <IVListTable />
                </div>
                <BannerPositionDialog />
                <TrendingPositionDialog />
            </div>
        );
    }
}

Studio.propTypes = {
    ivListInProgress: PropTypes.bool.isRequired,
    ivListSortBy: PropTypes.string.isRequired,
    ivLists: PropTypes.array.isRequired,
    setShowBanner: PropTypes.func.isRequired,
    showBanner: PropTypes.bool.isRequired,
    fetchIVList: PropTypes.func,
    ivListLimit: PropTypes.number,
    ivListOrder: PropTypes.string,
    ivListSearchKey: PropTypes.string,
    ivListSkip: PropTypes.number,
    ivListTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        lang: state.language,
        snackbar: state.snackbar.message,
        tabValue: state.campaign.tabValue,

        ivLists: state.studio.ivListData.list,
        ivListInProgress: state.studio.ivListData.inProgress,
        ivListSkip: state.studio.ivListData.skip,
        ivListLimit: state.studio.ivListData.limit,
        ivListSortBy: state.studio.ivListData.sortBy,
        ivListOrder: state.studio.ivListData.order,
        ivListTotal: state.studio.ivListData.count,
        ivListSearchKey: state.studio.ivListData.searchKey,
        showBanner: state.studio.showBanner,
    };
};

const actionToProps = {
    fetchIVList,
    showMintAccessDialog,
    showLaunchpadDialog,
    setShowBanner,
};

export default withRouter(connect(stateToProps, actionToProps)(Studio));
