import { DEFAULT_COUNT, DEFAULT_ORDER, DEFAULT_SORT_BY } from '../config';
import { combineReducers } from 'redux';
import {
    IS_BANNER_POSITION_DIALOG_HIDE, IS_BANNER_POSITION_DIALOG_SHOW,
    IS_BANNER_POSITION_SET,
    IS_TRENDING_POSITION_DIALOG_HIDE,
    IS_TRENDING_POSITION_DIALOG_SHOW,
    IS_TRENDING_POSITION_SET,
    IV_DATA_UPDATE_ERROR,
    IV_DATA_UPDATE_IN_PROGRESS,
    IV_DATA_UPDATE_SUCCESS,
    IV_LIST_FETCH_ERROR,
    IV_LIST_FETCH_IN_PROGRESS,
    IV_LIST_FETCH_SUCCESS,
    SHOW_BANNER_SET,
} from '../constants/studio';

const ivListData = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case IV_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IV_LIST_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case IV_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ivDataUpdate = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case IV_LIST_FETCH_IN_PROGRESS:
    case IV_DATA_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IV_LIST_FETCH_SUCCESS:
    case IV_DATA_UPDATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case IV_LIST_FETCH_ERROR:
    case IV_DATA_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const bannerPosition = (state = '', action) => {
    if (action.type === IS_BANNER_POSITION_SET) {
        return action.value;
    } else if (action.type === IS_BANNER_POSITION_DIALOG_SHOW) {
        return action.value && action.value.is_banner && action.value.banner_position ? (action.value.banner_position) : '';
    } else if (action.type === IS_BANNER_POSITION_DIALOG_HIDE) {
        return '';
    }

    return state;
};

const trendingPosition = (state = '', action) => {
    if (action.type === IS_TRENDING_POSITION_SET) {
        return action.value;
    } else if (action.type === IS_TRENDING_POSITION_DIALOG_SHOW) {
        return action.value && action.value.is_trending && action.value.trending_position ? (action.value.trending_position) : '';
    } else if (action.type === IS_TRENDING_POSITION_DIALOG_HIDE) {
        return '';
    }

    return state;
};

const bannerPositionDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case IS_BANNER_POSITION_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };

    case IS_BANNER_POSITION_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const trendingPositionDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case IS_TRENDING_POSITION_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };

    case IS_TRENDING_POSITION_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const showBanner = (state = false, action) => {
    if (action.type === SHOW_BANNER_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    ivListData,
    ivDataUpdate,

    bannerPosition,
    bannerPositionDialog,
    trendingPosition,
    trendingPositionDialog,
    showBanner,
});
