import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../../components/TextField';
import variables from '../../../../utils/variables';
import { setFeaturedPosition } from '../../../../actions/home';

const PositionTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="address_field"
            id="position-value"
            name="position"
            placeholder={variables[props.lang]['enter_position']}
            value={props.value}
            onChange={handleChange}/>
    );
};

PositionTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.home.featuredPosition,
    };
};

const actionsToProps = {
    onChange: setFeaturedPosition,
};

export default connect(stateToProps, actionsToProps)(PositionTextField);
