import React from 'react';
import DataTable from '../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../components/CircularProgress';
import moment from 'moment';
import ImageOnLoad from '../../../components/ImageOnLoad/BackgroundImage';
import { Button, Tooltip } from '@material-ui/core';
import {
    fetchIVList,
    showBannerPositionDialog,
    showTrendingPositionDialog,
    updateIVData,
} from '../../../actions/studio';
import { mediaReference } from '../../../utils/ipfs';

const IVListTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.ivListInProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.ivLists && props.ivLists.length === 0) {
                return;
            }

            props.fetchIVList(props.ivListSkip, props.ivListLimit, props.ivListSortBy, props.ivListOrder, searchText, props.showBanner);
        },
        onChangePage: (currentPage) => {
            if (props.ivLists && props.ivLists.length === 0) {
                return;
            }

            props.fetchIVList(props.ivListLimit * currentPage, props.ivListLimit, props.ivListSortBy, props.ivListOrder, props.ivListSearchKey, props.showBanner);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.ivLists && props.ivLists.length === 0) {
                return;
            }

            props.fetchIVList(props.ivListSkip, numberOfRows, props.ivListSortBy, props.ivListOrder, props.ivListSearchKey, props.showBanner);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.ivLists && props.ivLists.length === 0) {
                return;
            }

            // if (changedColumn === 'isFeatured' && direction === 'desc') {
            //     props.fetchCollections(props.skip, props.limit, changedColumn, direction, props.searchKey, 'isFeatured');
            // } else {
            props.fetchIVList(props.ivListSkip, props.ivListLimit, changedColumn, direction, props.ivListSearchKey, props.showBanner);
            // }
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.ivListTotal,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
    };

    const handleCollection = (collection, section, value) => {
        const id = collection && collection._id;
        let data = {};
        if (section === 'is_banner') {
            data = {
                isBanner: value,
            };
        } else if (section === 'is_trending') {
            data = {
                isTrending: value,
            };
        }

        props.updateIVData(id, data, (cb) => {
            if (!cb) {
                props.fetchIVList(props.ivListSkip, props.ivListLimit, props.ivListSortBy, props.ivListOrder, props.ivListSearchKey, props.showBanner);
            }
        });
    };

    const columns = [{
        name: 'image',
        label: 'Image',
        options: {
            sort: false,
            customBodyRender: function (value) {
                const previewURL = value.video_thumbnail_cdn
                    ? value.video_thumbnail_cdn.variants && value.video_thumbnail_cdn.variants[0]
                    : value.video_thumbnail
                        ? value.video_thumbnail
                        : value.nft
                            ? value.nft.cloudflare_cdn && value.nft.cloudflare_cdn.thumbnail && value.nft.cloudflare_cdn.thumbnail
                            : value.nft.preview_uri && value.nft.preview_uri;

                return (
                    <React.Fragment>
                        <ImageOnLoad
                            cdn={value.nft && value.nft.cloudflare_cdn && value.nft.cloudflare_cdn.variants}
                            className="collection_icon"
                            src={mediaReference(previewURL)}/>
                    </React.Fragment>
                );
            },
        },
    }, {
        name: 'title',
        label: 'Title',
        options: {
            customBodyRender: function (value) {
                const name = value.title;
                return (
                    <Tooltip title={name}>
                        <div
                            className="collection_name">
                            {name}
                        </div>
                    </Tooltip>
                );
            },
        },
    }, {
        name: 'denom.name',
        label: 'Collection Name',
        options: {
            customBodyRender: function (value) {
                const name = value.denom && value.denom.name;
                return (
                    <Tooltip title={name}>
                        <div
                            className="collection_name">
                            {name}
                        </div>
                    </Tooltip>
                );
            },
        },
    }, {
        name: 'number_of_interactions',
        label: 'Number of Interactions',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'participants_count',
        label: 'Participants Count',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'participations',
        label: 'Participations',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'views_count',
        label: 'Views Count',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'created_at',
        label: 'Created Date',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value && moment(value).format('MMM Do \'YY')}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions">
                        {value && value.is_trending
                            ? <Button onClick={() => handleCollection(value, 'is_trending', false)}>
                                Remove from Trending
                            </Button>
                            : <Button onClick={() => props.showTrendingPositionDialog(value)}>
                                Add to Trending
                            </Button>}
                        {value && value.is_banner
                            ? <Button
                                className="primary_button"
                                onClick={() => handleCollection(value, 'is_banner', false)}>
                                Remove from Banner
                            </Button>
                            : <Button
                                className="primary_button"
                                onClick={() => props.showBannerPositionDialog(value)}>
                                Add to Banner
                            </Button>}
                    </div>

                );
            },
        },
    }];

    const tableData = props.ivLists && props.ivLists.length > 0
        ? props.ivLists.map((item, index) =>
            [item,
                item,
                item,
                item.number_of_interactions,
                item.participants_count,
                item.participations,
                item.views_count,
                item.created_at,
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.ivListInProgress ? [] : tableData}
                // name={'Mint Requests'}
                options={options}/>
        </>
    );
};

IVListTable.propTypes = {
    fetchIVList: PropTypes.func.isRequired,
    ivListInProgress: PropTypes.bool.isRequired,
    ivListSortBy: PropTypes.string.isRequired,
    ivLists: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    showBanner: PropTypes.bool.isRequired,
    showBannerPositionDialog: PropTypes.func.isRequired,
    showTrendingPositionDialog: PropTypes.func.isRequired,
    updateIVData: PropTypes.func.isRequired,
    ivListLimit: PropTypes.number,
    ivListOrder: PropTypes.string,
    ivListSearchKey: PropTypes.string,
    ivListSkip: PropTypes.number,
    ivListTotal: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        ivLists: state.studio.ivListData.list,
        ivListInProgress: state.studio.ivListData.inProgress,
        ivListSkip: state.studio.ivListData.skip,
        ivListLimit: state.studio.ivListData.limit,
        ivListSortBy: state.studio.ivListData.sortBy,
        ivListOrder: state.studio.ivListData.order,
        ivListTotal: state.studio.ivListData.count,
        ivListSearchKey: state.studio.ivListData.searchKey,
        showBanner: state.studio.showBanner,
    };
};

const actionsToProps = {
    fetchIVList,
    updateIVData,

    showBannerPositionDialog,
    showTrendingPositionDialog,
};

export default connect(stateToProps, actionsToProps)(IVListTable);
