import React from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { hideMintAccessDialog } from '../../../../actions/home';
import { connect } from 'react-redux';
import AddressTextField from './AddressTextField';

const MintAccessDialog = (props) => {
    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog"
            open={props.open}
            onClose={props.hideMintAccessDialog}>
            <div className="mint_access">
                <h2> Mint Access </h2>
                <AddressTextField/>
                <Button>
                    Mint Access
                </Button>
            </div>
        </Dialog>
    );
};

MintAccessDialog.propTypes = {
    hideMintAccessDialog: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.mintAccessDialog.open,
    };
};

const actionsToProps = {
    hideMintAccessDialog,
};

export default connect(stateToProps, actionsToProps)(MintAccessDialog);
