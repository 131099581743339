import React from 'react';
import './index.css';
import Table from './Table';

const Banners = () => {
    return (
        <div className="collection_table banners_table">
            <Table/>
        </div>
    );
};

export default Banners;
