export const TAB_VALUE_SET = 'TAB_VALUE_SET';
export const CAMPAIGN_LIST_DIALOG_SHOW = 'CAMPAIGN_LIST_DIALOG_SHOW';
export const CAMPAIGN_LIST_DIALOG_HIDE = 'CAMPAIGN_LIST_DIALOG_HIDE';

export const CAMPAIGN_LIST_ADD_SUCCESS = 'CAMPAIGN_LIST_ADD_SUCCESS';
export const CAMPAIGN_LIST_ADD_IN_PROGRESS = 'CAMPAIGN_LIST_ADD_IN_PROGRESS';
export const CAMPAIGN_LIST_ADD_ERROR = 'CAMPAIGN_LIST_ADD_ERROR';

export const CAMPAIGNS_FETCH_IN_PROGRESS = 'CAMPAIGNS_FETCH_IN_PROGRESS';
export const CAMPAIGNS_FETCH_SUCCESS = 'CAMPAIGNS_FETCH_SUCCESS';
export const CAMPAIGNS_FETCH_ERROR = 'CAMPAIGNS_FETCH_ERROR';

export const CAMPAIGNS_UPDATE_IN_PROGRESS = 'CAMPAIGNS_UPDATE_IN_PROGRESS';
export const CAMPAIGNS_UPDATE_SUCCESS = 'CAMPAIGNS_UPDATE_SUCCESS';
export const CAMPAIGNS_UPDATE_ERROR = 'CAMPAIGNS_UPDATE_ERROR';

export const CAMPAIGNS_DELETE_IN_PROGRESS = 'CAMPAIGNS_DELETE_IN_PROGRESS';
export const CAMPAIGNS_DELETE_SUCCESS = 'CAMPAIGNS_DELETE_SUCCESS';
export const CAMPAIGNS_DELETE_ERROR = 'CAMPAIGNS_DELETE_ERROR';

export const CAMPAIGN_NAME_SET = 'CAMPAIGN_NAME_SET';
export const CAMPAIGN_DESCRIPTION_SET = 'CAMPAIGN_DESCRIPTION_SET';
export const CAMPAIGN_START_DATE_SET = 'CAMPAIGN_START_DATE_SET';
export const CAMPAIGN_END_DATE_SET = 'CAMPAIGN_END_DATE_SET';
export const CAMPAIGN_PRICE_SET = 'CAMPAIGN_PRICE_SET';
export const CAMPAIGN_DENOMS_SET = 'CAMPAIGN_DENOMS_SET';
export const CAMPAIGN_LAUNCHPAD_DENOMS_SET = 'CAMPAIGN_LAUNCHPAD_DENOMS_SET';

export const DENOMS_FETCH_SUCCESS = 'DENOMS_FETCH_SUCCESS';
export const DENOMS_FETCH_IN_PROGRESS = 'DENOMS_FETCH_IN_PROGRESS';
export const DENOMS_FETCH_ERROR = 'DENOMS_FETCH_ERROR';

export const LAUNCHPAD_DENOMS_FETCH_SUCCESS = 'LAUNCHPAD_DENOMS_FETCH_SUCCESS';
export const LAUNCHPAD_DENOMS_FETCH_IN_PROGRESS = 'LAUNCHPAD_DENOMS_FETCH_IN_PROGRESS';
export const LAUNCHPAD_DENOMS_FETCH_ERROR = 'LAUNCHPAD_DENOMS_FETCH_ERROR';

export const CAMPAIGN_QUICK_VIEW_SHOW = 'CAMPAIGN_QUICK_VIEW_SHOW';
export const CAMPAIGN_QUICK_VIEW_HIDE = 'CAMPAIGN_QUICK_VIEW_HIDE';

export const DELETE_CAMPAIGN_DIALOG_SHOW = 'DELETE_CAMPAIGN_DIALOG_SHOW';
export const DELETE_CAMPAIGN_DIALOG_HIDE = 'DELETE_CAMPAIGN_DIALOG_HIDE';
