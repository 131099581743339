export const config = {
    PREFIX: 'omniflix',
};

export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const IPFS_REFERENCE_PATH = 'ipfs://';

export const ibcTokens = {
    channel: 'channel-0',
    created_at: '2022-02-23T11:03:26.029Z',
    destination_channel: 'channel-8',
    ibc_denom_hash: 'ibc/958A1B1927FC2E31ED22B2A1749F01F7818CDE856EF4EB8376CB4AC747175999',
    network: {
        address_prefix: 'streampay',
        api_address: 'https://rest.streampay.omniflix.network',
        chain_id: 'streampay-1',
        decimals: 6,
        denom: 'uspay',
        display_denom: 'SPAY',
        explorer: null,
        name: 'Stream Pay',
        rpc_address: 'https://rpc.streampay.omniflix.network',
    },
    status: 'ENABLED',
    updated_at: '2022-02-23T11:03:26.029Z',
};

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_COUNT = 10;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
