import * as React from 'react';
import Switch from '@mui/material/Switch';
import * as PropTypes from 'prop-types';
import { setLoginSwitch } from '../../actions/login';
import withRouter from '../../components/WithRouter';
import { connect } from 'react-redux';
import { alpha, FormControl, FormGroup, styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#c8991a',
        '&:hover': {
            backgroundColor: alpha('#c8991a', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#c8991a',
    },
}));

const SwitchButton = (props) => {
    const handleChange = (event) => {
        props.setLoginSwitch(event.target.checked);
    };

    return (
        <FormControl className="switch_button" component="fieldset" variant="standard">
            <FormGroup>
                <FormControlLabel
                    control={
                        <PinkSwitch checked={props.switch} color="secondary" onChange={handleChange} />
                    }
                    label="Studio Login"
                />
            </FormGroup>
        </FormControl>
    );
};

SwitchButton.propTypes = {
    setLoginSwitch: PropTypes.func.isRequired,
    switch: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        switch: state.login.loginSwitch.value,
    };
};

const actionsToProps = {
    setLoginSwitch,
};

export default withRouter(connect(stateToProps, actionsToProps)(SwitchButton));
