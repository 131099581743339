import {
    CAMPAIGN_DENOMS_SET,
    CAMPAIGN_DESCRIPTION_SET,
    CAMPAIGN_END_DATE_SET,
    CAMPAIGN_LAUNCHPAD_DENOMS_SET,
    CAMPAIGN_LIST_ADD_ERROR,
    CAMPAIGN_LIST_ADD_IN_PROGRESS,
    CAMPAIGN_LIST_ADD_SUCCESS,
    CAMPAIGN_LIST_DIALOG_HIDE,
    CAMPAIGN_LIST_DIALOG_SHOW,
    CAMPAIGN_NAME_SET,
    CAMPAIGN_PRICE_SET,
    CAMPAIGN_QUICK_VIEW_HIDE,
    CAMPAIGN_QUICK_VIEW_SHOW,
    CAMPAIGN_START_DATE_SET,
    CAMPAIGNS_DELETE_ERROR,
    CAMPAIGNS_DELETE_IN_PROGRESS,
    CAMPAIGNS_DELETE_SUCCESS,
    CAMPAIGNS_FETCH_ERROR,
    CAMPAIGNS_FETCH_IN_PROGRESS,
    CAMPAIGNS_FETCH_SUCCESS,
    CAMPAIGNS_UPDATE_ERROR,
    CAMPAIGNS_UPDATE_IN_PROGRESS,
    CAMPAIGNS_UPDATE_SUCCESS,
    DELETE_CAMPAIGN_DIALOG_HIDE,
    DELETE_CAMPAIGN_DIALOG_SHOW,
    DENOMS_FETCH_ERROR,
    DENOMS_FETCH_IN_PROGRESS,
    DENOMS_FETCH_SUCCESS,
    LAUNCHPAD_DENOMS_FETCH_ERROR,
    LAUNCHPAD_DENOMS_FETCH_IN_PROGRESS,
    LAUNCHPAD_DENOMS_FETCH_SUCCESS,
    TAB_VALUE_SET,
} from '../constants/campaign';
import { ALL_DENOM_URL, CAMPAIGNS_URL, DENOM_URL, urlAddCampaign, urlSingleCampaign } from '../constants/url';
import Axios from 'axios';

export const setTabValue = (value) => {
    return {
        type: TAB_VALUE_SET,
        value,
    };
};

export const showCampaignListDialog = (value) => {
    return {
        type: CAMPAIGN_LIST_DIALOG_SHOW,
        value,
    };
};

export const hideCampaignListDialog = () => {
    return {
        type: CAMPAIGN_LIST_DIALOG_HIDE,
    };
};

export const setCampaignName = (value) => {
    return {
        type: CAMPAIGN_NAME_SET,
        value,
    };
};

export const setCampaignDescription = (value) => {
    return {
        type: CAMPAIGN_DESCRIPTION_SET,
        value,
    };
};

export const setCampaignStartDate = (value) => {
    return {
        type: CAMPAIGN_START_DATE_SET,
        value,
    };
};

export const setCampaignEndDate = (value) => {
    return {
        type: CAMPAIGN_END_DATE_SET,
        value,
    };
};

export const setCampaignPrice = (value) => {
    return {
        type: CAMPAIGN_PRICE_SET,
        value,
    };
};

export const setCampaignDenoms = (value) => {
    return {
        type: CAMPAIGN_DENOMS_SET,
        value,
    };
};

export const setCampaignLaunchPadDenoms = (value) => {
    return {
        type: CAMPAIGN_LAUNCHPAD_DENOMS_SET,
        value,
    };
};

export const showCampaignQuickView = (value) => {
    return {
        type: CAMPAIGN_QUICK_VIEW_SHOW,
        value,
    };
};

export const hideCampaignQuickView = () => {
    return {
        type: CAMPAIGN_QUICK_VIEW_HIDE,
    };
};

export const showDeleteCampaignDialog = (value) => {
    return {
        type: DELETE_CAMPAIGN_DIALOG_SHOW,
        value,
    };
};

export const hideDeleteCampaignDialog = () => {
    return {
        type: DELETE_CAMPAIGN_DIALOG_HIDE,
    };
};

const fetchCampaignsInProgress = () => {
    return {
        type: CAMPAIGNS_FETCH_IN_PROGRESS,
    };
};

const fetchCampaignsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: CAMPAIGNS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchCampaignsError = (message) => {
    return {
        type: CAMPAIGNS_FETCH_ERROR,
        message,
    };
};

export const fetchCampaigns = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchCampaignsInProgress());

    const url = CAMPAIGNS_URL(skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchCampaignsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchCampaignsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const addCampaignListInProgress = () => {
    return {
        type: CAMPAIGN_LIST_ADD_IN_PROGRESS,
    };
};

const addCampaignListSuccess = (value, message) => {
    return {
        type: CAMPAIGN_LIST_ADD_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const addCampaignListError = (message) => {
    return {
        type: CAMPAIGN_LIST_ADD_ERROR,
        message,
        variant: 'error',
    };
};

export const addCampaignList = (data, cb) => (dispatch) => {
    dispatch(addCampaignListInProgress());

    const url = urlAddCampaign();
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(addCampaignListSuccess(res.data && res.data.result, 'Campaign Added Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(addCampaignListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const fetchDenomsInProgress = () => {
    return {
        type: DENOMS_FETCH_IN_PROGRESS,
    };
};

const fetchDenomsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: DENOMS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchDenomsError = (message) => {
    return {
        type: DENOMS_FETCH_ERROR,
        message,
    };
};

export const fetchDenoms = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchDenomsInProgress());

    const url = ALL_DENOM_URL(false, skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchDenomsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchDenomsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const updateCampaignListInProgress = () => {
    return {
        type: CAMPAIGNS_UPDATE_IN_PROGRESS,
    };
};

const updateCampaignListSuccess = (value, message) => {
    return {
        type: CAMPAIGNS_UPDATE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const updateCampaignListError = (message) => {
    return {
        type: CAMPAIGNS_UPDATE_ERROR,
        message,
        variant: 'error',
    };
};

export const updateCampaignList = (data, id, cb) => (dispatch) => {
    dispatch(updateCampaignListInProgress());

    const url = urlSingleCampaign(id);
    Axios.put(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(updateCampaignListSuccess(res.data && res.data.result, 'Campaign Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(updateCampaignListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const deleteCampaignListInProgress = () => {
    return {
        type: CAMPAIGNS_DELETE_IN_PROGRESS,
    };
};

const deleteCampaignListSuccess = (value, message) => {
    return {
        type: CAMPAIGNS_DELETE_SUCCESS,
        value,
        message,
        variant: 'success',
    };
};

const deleteCampaignListError = (message) => {
    return {
        type: CAMPAIGNS_DELETE_ERROR,
        message,
        variant: 'error',
    };
};

export const deleteCampaignList = (id, cb) => (dispatch) => {
    dispatch(deleteCampaignListInProgress());

    const url = urlSingleCampaign(id);
    Axios.delete(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(deleteCampaignListSuccess(res.data && res.data.result, 'Campaign Updated Successfully'));
            if (cb) {
                cb(null);
            }
        })
        .catch((error) => {
            dispatch(deleteCampaignListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(error);
            }
        });
};

const fetchLaunchpadDenomsInProgress = () => {
    return {
        type: LAUNCHPAD_DENOMS_FETCH_IN_PROGRESS,
    };
};

const fetchLaunchpadDenomsSuccess = (items, count, skip, limit, sortBy, order) => {
    return {
        type: LAUNCHPAD_DENOMS_FETCH_SUCCESS,
        items: items,
        count,
        skip,
        limit,
        sortBy,
        order,
    };
};

const fetchLaunchpadDenomsError = (message) => {
    return {
        type: LAUNCHPAD_DENOMS_FETCH_ERROR,
        message,
    };
};

export const fetchLaunchpadDenoms = (skip, limit, sortBy, order, searchKey) => (dispatch) => {
    dispatch(fetchLaunchpadDenomsInProgress());

    const url = DENOM_URL(true, skip, limit, sortBy, order, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            const items = res.data && res.data.result && res.data.result.list;
            const count = res.data && res.data.result && res.data.result.count;
            dispatch(fetchLaunchpadDenomsSuccess(items, count, skip, limit, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchLaunchpadDenomsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
