import React from 'react';
import DataTable from '../../../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../../../components/CircularProgress';
import moment from 'moment';
import { Button } from '@material-ui/core';
import {
    fetchCampaigns,
    showCampaignListDialog,
    showCampaignQuickView,
    showDeleteCampaignDialog,
} from '../../../../actions/campaign';
import Icon from '../../../../components/Icon';
import './index.css';

const Table = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCampaigns(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCampaigns(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCampaigns(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchCampaigns(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        viewColumns: true,
        title: true,
        customToolbar: () => {
            return (
                <div
                    className="add_button"
                    onClick={() => props.showCampaignListDialog()}>
                    <Icon className="add-circle" icon="add-circle" />
                </div>
            );
        },
    };

    const columns = [{
        name: 'name',
        label: 'Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div
                        className="collection_name">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'description',
        label: 'Description',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div
                        className="collection_name">
                        {value || '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'denoms',
        label: 'Denoms',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'max_usd_price',
        label: 'Max USD Price',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'start_date',
        label: 'Start Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value ? moment(value).format('MMM Do \'YY') : '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'end_date',
        label: 'End Date',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value ? moment(value).format('MMM Do \'YY') : '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'created_at',
        label: 'Created Date',
        options: {
            display: false,
            customBodyRender: function (value) {
                return (
                    <div className="created_date">
                        {value ? moment(value).format('MMM Do \'YY') : '-'}
                    </div>
                );
            },
        },
    }, {
        name: 'actions',
        label: 'Actions',
        options: {
            sort: false,
            customBodyRender: function (value) {
                return (
                    <div className="actions actions_campaign">
                        <Button onClick={() => props.showCampaignQuickView(value)}>
                            <Icon className="eye" icon="eye" />
                        </Button>
                        <Button onClick={() => props.showCampaignListDialog(value)}>
                            <Icon className="edit" icon="edit" />
                        </Button>
                        <Button onClick={() => props.showDeleteCampaignDialog(value)}>
                            <Icon className="bin" icon="bin" />
                        </Button>
                    </div>

                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.name,
                item.description || '',
                item.denoms && item.denoms.length,
                item.max_usd_price,
                item.start_date ? item.start_date : '',
                item.end_date ? item.end_date : '',
                item.created_at ? item.created_at : '',
                item],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

Table.propTypes = {
    fetchCampaigns: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    showCampaignListDialog: PropTypes.func.isRequired,
    showCampaignQuickView: PropTypes.func.isRequired,
    showDeleteCampaignDialog: PropTypes.func.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.campaign.campaignsList.list,
        inProgress: state.campaign.campaignsList.inProgress,
        skip: state.campaign.campaignsList.skip,
        limit: state.campaign.campaignsList.limit,
        sortBy: state.campaign.campaignsList.sortBy,
        order: state.campaign.campaignsList.order,
        total: state.campaign.campaignsList.count,
        searchKey: state.campaign.campaignsList.searchKey,
    };
};

const actionsToProps = {
    fetchCampaigns,
    showCampaignListDialog,
    showCampaignQuickView,
    showDeleteCampaignDialog,
};

export default connect(stateToProps, actionsToProps)(Table);
