import React from 'react';
import './index.css';
import { Button, Dialog } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '../../../../../components/CircularProgress';
import {
    hideBannerListDialog,
    addBannerList,
    fetchBanners,
    updateBannerList,
} from '../../../../../actions/home';
import NameTextField from './NameTextField';
import StartDateTextField from './StartDateTextField';
import EndDateTextField from './EndDateTextField';
import { showMessage } from '../../../../../actions/snackbar';
import MobileImageField from './MobileImageField';
import WebImageField from './WebImageField';
import TabImageField from './TabImageField';
import LargeScreenImageField from './LargeScreenImageField';
import StatusSwitchField from './StatusSwitchField';
import RedirectionUrlField from './RedirectionUrlField';

const AddBannerDialog = (props) => {
    const addBanner = () => {
        const data = {};
        if (props.nameValue !== '') {
            data.name = props.nameValue;
        }
        if (props.startDate !== null) {
            data.startTime = props.startDate;
        }
        if (props.endDate !== null) {
            data.endTime = props.endDate;
        }
        data.type = 'flix-friday-banner';
        if (props.mobileImageValue !== '') {
            data.image = { ...data.image, mobile: props.mobileImageValue };
        }
        if (props.webImageValue !== '') {
            data.image = { ...data.image, web: props.webImageValue };
        }
        if (props.tabImageValue !== '') {
            data.image = { ...data.image, tab: props.tabImageValue };
        }
        if (props.largeScreenImageValue !== '') {
            data.image = { ...data.image, large_screen: props.largeScreenImageValue };
        }
        if (props.redirectionUrlValue !== '') {
            data.redirectionURL = props.redirectionUrlValue;
        }
        if (props.nameValue) {
            props.addBannerList(data, (cb) => {
                if (!cb) {
                    props.hideBannerListDialog();
                    props.fetchBanners();
                }
            });
        } else {
            props.showMessage('Please add all fields');
        }
    };

    const editBanner = () => {
        const data = {};
        if (props.nameValue !== '') {
            data.name = props.nameValue;
        }
        if (props.startDate !== null) {
            data.startTime = props.startDate;
        }
        if (props.endDate !== null) {
            data.endTime = props.endDate;
        }
        if (props.mobileImageValue !== '') {
            data.image = { ...data.image, mobile: props.mobileImageValue };
        }
        if (props.webImageValue !== '') {
            data.image = { ...data.image, web: props.webImageValue };
        }
        if (props.tabImageValue !== '') {
            data.image = { ...data.image, tab: props.tabImageValue };
        }
        if (props.largeScreenImageValue !== '') {
            data.image = { ...data.image, large_screen: props.largeScreenImageValue };
        }
        if (props.redirectionUrlValue !== '') {
            data.redirectionURL = props.redirectionUrlValue;
        }
        if (props.nameValue) {
            props.updateBannerList(data, props.value && props.value._id, (cb) => {
                if (!cb) {
                    props.hideBannerListDialog();
                    props.fetchBanners();
                }
            });
        } else {
            props.showMessage('Please add all fields');
        }
    };

    return (
        <Dialog
            aria-describedby="preview-dialog-description"
            aria-labelledby="preview-dialog-title"
            className="dialog mint_dialog banner_dialog"
            open={props.open}
            onClose={props.hideBannerListDialog}>
            <div className="mint_access banner_dialog_section">
                <h2>Add Banner</h2>
                <div className="banner_fields">
                    <NameTextField/>
                    <RedirectionUrlField/>
                    <StartDateTextField/>
                    <EndDateTextField/>
                    <MobileImageField/>
                    <TabImageField/>
                    <WebImageField/>
                    <LargeScreenImageField/>
                    {props.value && props.value._id &&
                        <StatusSwitchField/>}
                </div>
                {props.value && props.value._id
                    ? <Button onClick={editBanner}>
                        {props.inProgress && <CircularProgress/>}
                        Edit Banner
                    </Button>
                    : <Button onClick={addBanner}>
                        {props.inProgress && <CircularProgress/>}
                        Add Banner
                    </Button>}
            </div>
        </Dialog>
    );
};

AddBannerDialog.propTypes = {
    addBannerList: PropTypes.func.isRequired,
    bannerType: PropTypes.string.isRequired,
    fetchBanners: PropTypes.func.isRequired,
    hideBannerListDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    largeScreenImageValue: PropTypes.string.isRequired,
    mobileImageValue: PropTypes.string.isRequired,
    nameValue: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    redirectionUrlValue: PropTypes.string.isRequired,
    showMessage: PropTypes.func.isRequired,
    tabImageValue: PropTypes.string.isRequired,
    updateBannerList: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    webImageValue: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        open: state.home.bannerListDialog.open,
        value: state.home.bannerListDialog.value,
        inProgress: state.home.addBanner.inProgress,

        nameValue: state.home.nameValue,
        startDate: state.home.startDate,
        endDate: state.home.endDate,
        bannerType: state.home.bannerType,
        mobileImageValue: state.home.bannerMobileImageValue,
        webImageValue: state.home.bannerWebImageValue,
        tabImageValue: state.home.bannerTabImageValue,
        largeScreenImageValue: state.home.bannerLargeScreenImageValue,
        redirectionUrlValue: state.home.bannerRedirectionUrlValue,
    };
};

const actionToProps = {
    hideBannerListDialog,
    fetchBanners,
    showMessage,
    addBannerList,
    updateBannerList,
};

export default connect(stateToProps, actionToProps)(AddBannerDialog);
